import React, { createContext, Dispatch, SetStateAction, useCallback, useState } from "react"
import { POINTER } from "./interfaces";
import * as CONF from 'src/configs'


const isDebug = CONF.isDebug && false

/* PointerStatus Context ----------------------------------- */
export const PointerStatusContext = createContext<number>(POINTER.HIDDEN)
export const ChangePointerContext = createContext<(status: number, from: string) => void>(() => { })

export const PointerStatusProvider = (props: { children: React.ReactNode }) => {
    const [pointerStatus, setPointerStatus] = useState<number>(POINTER.HIDDEN)

    const changePointer = useCallback((status: number, from: string) => {
        if (isDebug) console.log(`PointerStatusを変更します ${from}`)

        setPointerStatus(status)
    }, [setPointerStatus])

    return (
        <PointerStatusContext.Provider value={pointerStatus}>
            <ChangePointerContext.Provider value={changePointer}>
                {props.children}
            </ChangePointerContext.Provider>
        </PointerStatusContext.Provider>
    );
};


/* LoadingProgress Context ----------------------------------- */
export const LoadingProgressContext = createContext(
    {} as {
        loadingProgress: number,
        setLoadingProgress: Dispatch<SetStateAction<number>>
    }
)

export const LoadingProgressProvider = (props: { children: React.ReactNode }) => {
    const [loadingProgress, setLoadingProgress] = useState(0)

    return (
        <LoadingProgressContext.Provider value={{ loadingProgress, setLoadingProgress }}>
            {props.children}
        </LoadingProgressContext.Provider>
    );
};


/* isOpeningEnd Context ----------------------------------- */
export const IsOpeningEndContext = createContext(
    {} as {
        isOpeningEnd: boolean,
        setIsOpeningEnd: Dispatch<SetStateAction<boolean>>
    }
)

export const IsOpeningEndProvider = (props: { children: React.ReactNode }) => {
    const [isOpeningEnd, setIsOpeningEnd] = useState(false)

    return (
        <IsOpeningEndContext.Provider value={{ isOpeningEnd, setIsOpeningEnd }}>
            {props.children}
        </IsOpeningEndContext.Provider>
    );
};
