import { CSSProperties, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'

import VirtualLab from 'src/VirtualLab/VirtualLab'
import * as CONF from 'src/configs'
import { ChangePointerContext, IsOpeningEndContext } from 'src/AppContexts'
import { POINTER } from 'src/interfaces'
import Gtag, { LABEL } from 'src/utils/Gtag'

type Props = {
    mode: number,
    setMode: Dispatch<SetStateAction<number>>
    setPrevMode: Dispatch<SetStateAction<number>>
    virtualLab: VirtualLab | null
}

const ModeSwitcher: React.FC<Props> = ({
    mode,
    setMode,
    setPrevMode,
    virtualLab
}) => {

    const changePointer = useContext(ChangePointerContext)
    const isOpeningEnd = useContext(IsOpeningEndContext).isOpeningEnd

    const [offsetX, setOffsetX] = useState(0)
    const button3dMode = useRef<HTMLButtonElement>(null)


    useEffect(() => {
        if (button3dMode.current) {
            if (button3dMode.current) setOffsetX(button3dMode.current.offsetWidth)
        }
        window.addEventListener("resize", () => {
            if (button3dMode.current) setOffsetX(button3dMode.current.offsetWidth)
        })
    }, [])


    const changeMode = (newMode: number) => {
        // オープニングが終わるまでは操作させない
        if (!isOpeningEnd) return

        if (CONF.isDebug) console.log(`モードを${newMode}に切り替えます`)

        // 変更前のモードを保持する
        setPrevMode(mode)
        if (virtualLab) {
            setMode(newMode)

            switch (newMode) {
                case 3:
                    virtualLab.onChangeMode(3)
                    Gtag.clickChangeModeButton(LABEL.TO_3D)
                    break
                case 2:
                    virtualLab.onChangeMode(2)
                    Gtag.clickChangeModeButton(LABEL.TO_2D)
                    break
                case 1:
                    virtualLab.onChangeMode(1)
                    Gtag.clickChangeModeButton(LABEL.TO_STANDARD)
                    break
            }
        }
    }


    const classNameIsOpening = isOpeningEnd ? " is-opening-done" : " is-opening"


    const modeSwitchBgStyle = (): CSSProperties => {
        switch (mode) {
            case 3:
                return { width: `${offsetX}px`, transform: `translateX(0px)` }
            case 2:
                return { width: `${offsetX}px`, transform: `translateX(${offsetX}px)` }
            case 1:
                return { width: `${offsetX}px`, transform: `translateX(${offsetX * 2}px)` }
        }
        return {}
    }


    return (
        <div className={"mode-switcher" + classNameIsOpening}>
            <div className="mode-switcher-inner">
                <button
                    className="mode-switch-button"
                    onClick={() => changeMode(3)}
                    onMouseMove={() => changePointer(POINTER.ONBUTTON, "ModeSwitcher")}
                    onMouseEnter={() => changePointer(POINTER.ONBUTTON, "ModeSwitcher")}
                    onMouseLeave={() => changePointer(POINTER.IDLE, "ModeSwitcher")}
                    ref={button3dMode}
                >
                    <span className="is-pc">3D ダイナミック</span>
                    <span className="is-sp">3D</span>
                </button>
                <button
                    className="mode-switch-button"
                    onClick={() => changeMode(2)}
                    onMouseMove={() => changePointer(POINTER.ONBUTTON, "ModeSwitcher")}
                    onMouseEnter={() => changePointer(POINTER.ONBUTTON, "ModeSwitcher")}
                    onMouseLeave={() => changePointer(POINTER.IDLE, "ModeSwitcher")}
                >
                    <span className="is-pc">2D ビジュアル</span>
                    <span className="is-sp">2D</span>
                </button>
                <button
                    className="mode-switch-button"
                    onClick={() => changeMode(1)}
                    onMouseMove={() => changePointer(POINTER.ONBUTTON, "ModeSwitcher")}
                    onMouseEnter={() => changePointer(POINTER.ONBUTTON, "ModeSwitcher")}
                    onMouseLeave={() => changePointer(POINTER.IDLE, "ModeSwitcher")}
                >
                    スタンダード
                </button>
                <div id='modeSwitchBg' className='mode-switch-bg' style={modeSwitchBgStyle()}></div>
            </div>
        </div>
    )
}

export default ModeSwitcher