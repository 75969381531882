import * as THREE from 'three'

import * as CONF from 'src/configs'
import VLHelpers from 'src/VirtualLab/VLHelpers'
import VLCard from 'src/VirtualLab/VLCard'
import VLCamera from './VLCamera'
import VLCardsGroup from './VLCardsGroup'

import VirtualLab from './VirtualLab'
import VLLines from './VLLines'
import Debugger from 'src/utils/Debugger'

import VLRecommender from './VLRecommender'
import VLGround from './VLGround'
import VLPointer from './VLPointer'
import VLCharactors from './VLCharactors'


/**
 * ThreejsのSceneを担当します
 */
class VLScene {

    private virtualLab: VirtualLab

    private mode: number = 3
    private scene: THREE.Scene
    private renderer: THREE.WebGLRenderer
    private _canvasElement: HTMLCanvasElement
    get canvasElement(): HTMLCanvasElement { return this._canvasElement }

    private vlCamera: VLCamera
    private vlCardsGroup: VLCardsGroup
    private vlLines: VLLines
    private vlGround: VLGround
    private vlCharactors: VLCharactors

    private vlRecommender: VLRecommender
    private vlPointer: VLPointer

    private vlHeplers: VLHelpers | null = null


    /**
     * Constructor
     * @param virtualLab 
     */
    constructor(
        virtualLab: VirtualLab,
        changePointer: (status: number, from: string) => void
    ) {

        this.virtualLab = virtualLab

        // setup renderer
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setClearColor(0xfefefe);

        // getCanvasElement
        this._canvasElement = this.renderer.domElement

        // setup Scene
        this.scene = new THREE.Scene();
        this.scene.fog = new THREE.Fog(0xffffff, CONF.sceneFogNear, CONF.sceneFogFar)

        // setup Camera
        this.vlCamera = new VLCamera(this.mode, this.canvasElement);
        const camera = this.vlCamera.camera

        // setup Cards
        this.vlCardsGroup = new VLCardsGroup()
        const cardsGroup = this.vlCardsGroup.cardsGroup
        this.scene.add(cardsGroup)

        // Recommender
        const cardsLength = this.vlCardsGroup.vlCards.length
        this.vlRecommender = new VLRecommender(this, cardsLength)

        // Helper
        if (CONF.isDebug) {
            this.vlHeplers = new VLHelpers(this.scene)
        }

        // VLLines
        this.vlLines = new VLLines(this.scene)

        // VLGround
        this.vlGround = new VLGround(this.scene)

        // setup VLCharactors
        this.vlCharactors = new VLCharactors(camera)
        this.vlCharactors.init(this.scene)

        // setup VLPointer
        this.vlPointer = new VLPointer(this, this.canvasElement, camera, this.vlCardsGroup, this.vlCharactors, changePointer)

        // start animation
        this.update()

        // add resize event listener
        window.addEventListener('resize', this.onResize);

        // Debugger
        if (CONF.isDebug) Debugger.vlScene = this
    }


    /**
     * threeをレンダリングする
     */
    private update = () => {
        requestAnimationFrame(this.update)

        this.renderer.render(this.scene, this.vlCamera.camera)
    }


    /**
     * on Resize
     */
    private onResize = () => {
        if (CONF.isDebug) console.log("Sceneのリサイズします");
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }


    /**
     * スタートアニメーション開始時に呼ばれる
     */
    onStart() {
        this.vlCardsGroup.onStart()
        this.vlCamera.onStart()
        this.vlPointer.onStart()
    }


    /**
     * スタートアニメーション完了時に呼ばれる
     */
    onStartEnd() {
        this.vlRecommender.init()
        this.vlPointer.onStartEnd()
    }


    /**
     * コンテンツを表示する
     */
    showContent(selectedCard: VLCard) {
        this.vlPointer.onShowContent()

        this.vlRecommender.disableWatchIdle()

        selectedCard.onSelect()

        const contentId = selectedCard.contentId

        // TODO:ここはカードに責任を持たせられる
        const cameraPos = selectedCard.getFrontPosition(this.mode)
        const targetPos = selectedCard.cardObj.getWorldPosition(new THREE.Vector3())
        this.vlCamera.showContent(cameraPos, targetPos)

        this.vlCardsGroup.onShowContent()

        if (CONF.isDebug) console.log(`contentId: ${contentId} のコンテンツを表示します`);

        this.virtualLab.sendShowContent(contentId)
    }



    /**
     * React側でコンテンツが表示された時に呼ばれる
     */
    onShowContentFromReact(contentId: number) {
        this.vlPointer.onShowContent()
        this.vlRecommender.disableWatchIdle()
        this.vlCardsGroup.onShowContent()

        const selectedCard = this.vlCardsGroup.getVLCardByContentId(contentId)
        if (selectedCard) {
            selectedCard.onSelect()
        }
    }


    /**
     * コンテンツが表示されていない状態へきりかえます
     */
    onHideContent() {
        this.vlPointer.onHideContent()

        switch (this.mode) {
            case 3:
                this.vlCamera.onHideContent(3)
                this.vlCardsGroup.onHideContent(3)
                break;
            case 2:
                this.vlCamera.onHideContent(2)
                this.vlCardsGroup.onHideContent(2)
                break
        }

        this.vlRecommender.enableWatchIdle()
    }



    /**
     * モード変更時に呼ばれる
     */
    onChangeMode(mode: number) {
        if (CONF.isDebug) console.log(`mode: ${mode}に切り替えます`)

        this.mode = mode

        this.vlPointer.onChangeMode(this.mode)

        switch (mode) {
            case 3:
                this.vlCardsGroup.transform3DFormation()
                this.vlCamera.onChangeMode(3)
                this.vlRecommender.enableWatchIdle()
                break
            case 2:
                this.vlCardsGroup.transform2DFormation()
                this.vlCamera.onChangeMode(2)
                this.vlRecommender.enableWatchIdle()
                break
            case 1:
                this.vlRecommender.disableWatchIdle()
                break
            default:
                if (CONF.isDebug) console.error(`mode:${mode} で渡された値が不正です`)
                break
        }
    }


    /**
     * レコメンドを開始します。
     */
    onStartRecommend() {
        this.vlCardsGroup.onStartRecommend()
        this.vlPointer.onStartRecommend()
    }



    /**
     * レコメンドします
     */
    onRecommend(indexOfRecommend: number) {
        const cardRecommend = this.vlCardsGroup.vlCards[indexOfRecommend]
        const targetPos = cardRecommend.cardObj.getWorldPosition(new THREE.Vector3())

        this.vlCamera.onRecommend(cardRecommend.getFrontPosition(this.mode), targetPos)
    }



    /**
     * レコメンドを停止します
     */
    onStopRecommend() {
        this.vlCardsGroup.onStopRecommend(this.mode)
        this.vlCamera.onStopRecommend(this.mode)
        this.vlPointer.onStopRecommend()
    }

}

export default VLScene