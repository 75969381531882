import VLScene from './VLScene'
import VLLoadingManager from './VLLoadingManager'
import { Dispatch, SetStateAction } from 'react'


/**
 * VirtualLab
 * ReactUIとの接続を担います
 */
class VirtualLab {

    private showContentFromVL: (contentId: number) => void

    private vlScene: VLScene


    /**
     * コンストラクター
     */
    constructor(
        showContentFromVL: (contentId: number) => void,
        setLoadingProgress: Dispatch<SetStateAction<number>>,
        changePointer: (status: number, from: string) => void,
    ) {
        VLLoadingManager.init(setLoadingProgress)

        this.showContentFromVL = showContentFromVL

        this.vlScene = new VLScene(this, changePointer)
    }


    /**
     * Canvas Elementを渡す
     */
    getCanvasElement(): HTMLCanvasElement {
        const canvasElement = this.vlScene.canvasElement
        return canvasElement
    }


    /**
     * VirtualLabをスタートします
     */
    onStart() {
        this.vlScene.onStart()
    }


    /**
     * VirtualLabのスタートが終わりました
     */
    onStartEnd() {
        this.vlScene.onStartEnd()
    }


    /**
     * モードを切り替えます
     */
    onChangeMode(mode: number) {
        this.vlScene.onChangeMode(mode)
    }


    /**
     * コンテンツを閉じる時に呼ばれます
     */
    onHideContent() {
        this.vlScene.onHideContent()
    }


    /**
     * React側でコンテンツが表示された時に呼ばれる
     */
    onShowContentFromReact(contentId: number) {
        this.vlScene.onShowContentFromReact(contentId)
    }


    /**
     * コンテンツを表示するよう、Reactへ伝える
     */
    sendShowContent(contentId: number) {
        this.showContentFromVL(contentId)
    }
}

export default VirtualLab;