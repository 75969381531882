import * as CONF from 'src/configs'
import VirtualLab from 'src/VirtualLab/VirtualLab'
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef } from 'react';
import ThreeActionButtons from './ThreeActionButtons';
import Contents from 'src/models/Contents';
import { ChangePointerContext, LoadingProgressContext } from 'src/AppContexts';
import { ContentStateTypes } from 'src/interfaces';

const isDebug = CONF.isDebug && true
const isShownThreeActionButtons = CONF.isDebug && false

type Props = {
    setContentState: Dispatch<SetStateAction<ContentStateTypes>>
    virtualLab: VirtualLab | null
    setVirtualLab: Dispatch<SetStateAction<VirtualLab | null>>
}

/**
 * VirtualLabを実体化し、VirtualLabとReactの接続を担う
 */
const ThreeCanvas: React.FC<Props> = ({
    setContentState,
    virtualLab,
    setVirtualLab
}) => {

    const changePointer = useContext(ChangePointerContext)
    const setLoadingProgress = useContext(LoadingProgressContext).setLoadingProgress

    const mountElement = useRef<HTMLDivElement>(null)


    const showContentFromVL = useCallback((contentId: number) => {
        setContentState({
            contentId: contentId,
            isShown: true,
            delay: CONF.delayShowContentFromVL
        })
    }, [setContentState])


    useEffect(() => {
        if (!virtualLab) {
            // 事前に必要なコンテンツのデータをロードしておく
            const promise = Contents.init()
            promise.then(() => {
                if (mountElement.current) {
                    const newVirtualLab = new VirtualLab(showContentFromVL, setLoadingProgress, changePointer)

                    const canvasElement = newVirtualLab.getCanvasElement()
                    mountElement.current.appendChild(canvasElement);

                    setVirtualLab(newVirtualLab)

                    if (isDebug) console.log(`VirtualLabを実体化し、ThreeCanvasでアタッチしました`)
                }
            })
        }
    }, [showContentFromVL, setLoadingProgress, changePointer, virtualLab, setVirtualLab])


    return (
        <div className="three-canvas">
            <div className="canvas" ref={mountElement} />

            {CONF.isDebug && isShownThreeActionButtons ? <ThreeActionButtons /> : null}
        </div>
    )
}

export default ThreeCanvas;