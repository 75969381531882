import { ContentType, POINTER } from "src/interfaces"
import { useContext } from "react"
import { ChangePointerContext } from "src/AppContexts"


type Props = {
    currentContents: ContentType[]
    showContent: (contentId: number) => void
}

/**
 * スタンダードモードで表示する現在のList
 */
const StandardList: React.FC<Props> = ({
    currentContents,
    showContent,
}) => {

    const contentItems = currentContents.map((content: ContentType) => {
        if (content.isStandard) {
            return (
                <Item
                    key={content.id}
                    content={content}
                    onClick={showContent}
                />
            )
        }
        return null
    })

    return (
        <div className="standard-list">
            <ul className="standard-items">
                {contentItems}
            </ul>
        </div>
    )
}


/* Content Item ----------------------------------- */
type ItemProps = {
    content: ContentType
    onClick: (contentId: number) => void
}

const Item = ({ content, onClick }: ItemProps) => {

    const changePointer = useContext(ChangePointerContext)

    return (
        <li className="standard-item">
            <button
                className="standard-item__inner"
                onClick={() => onClick(content.id)}
                onMouseMove={() => changePointer(POINTER.ONBUTTON, "StandardList")}
                onMouseEnter={() => changePointer(POINTER.ONBUTTON, "StandardList")}
                onMouseLeave={() => changePointer(POINTER.IDLE, "StandardList")}
            >
                <div className="image">
                    <img src={content.thumb} alt={content.title + "のサムネイル"} />
                </div>
                <div className="text-block">
                    <h3 className="title">{content.title}</h3>
                    {content.summary !== "" &&
                        <p className="summary">{content.summary}</p>
                    }
                </div>
            </button>
        </li>
    )
}

export default StandardList