import VLScene from "src/VirtualLab/VLScene";

class Debugger {

    static isActive = true;

    static isHelperCameraActive = false

    static vlScene: VLScene

    static action(actionId: number) {
        switch (actionId) {
            case 1:
                break;
            case 2:
                break;
        }
    }

}

export default Debugger