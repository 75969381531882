import * as THREE from 'three'

export const isDebug = false
export const url = window.location.href.slice(0, -1)

export const version = "202203072100";

export const delayShowContentFromVL = 1000

export const durationChangeMode = 2000
export const durationShowContent = 1000
export const durationHideContent = 1000


/* VirtualLab ----------------------------------- */
const rateStartSpeed = isDebug ? 0.2 : 1

// カメラが写す有効距離距離(= raycasterの反応範囲)
export const cameraNear = 1
export const cameraFar = 100

/* Scene */
export const sceneFogNear = 3
export const sceneFogFar = 35


/* CardsGroup */

export const cardsGroupPosInit = new THREE.Vector3(0, 35, 0)

export const cardsgroupDelayMoveStart1 = 1000 * rateStartSpeed
export const cardsgroupDurationMoveStart1 = 4500 * rateStartSpeed

export const cardsGroupRotationYInit = -3 * Math.PI
export const cardsgroupDurationRotateStart1 = 6000 * rateStartSpeed

// テーブルフォーメーションの高さ
export const cardsFormationTableY = 15

/* Cards  */

export const cardsDurationStart1 = 1000 * rateStartSpeed
export const cardsDurationRangeStart1 = 500 * rateStartSpeed

export const cardsDelayStart2 = 1500 * rateStartSpeed + cardsDurationStart1 + cardsDurationRangeStart1
export const cardsDurationStart2 = 1000 * rateStartSpeed
export const cardsDurationRangeStart2 = 500 * rateStartSpeed

// usually
export const cardsDurationRangeMove = 1000
export const cardsMinDurationMove = 1000


/* Camera  */
export const cameraPosInit = new THREE.Vector3(0, 35, 15)
export const cameraLookInit = new THREE.Vector3(0, 35, 0)

export const cameraDelayMoveStart1 = 1000 * rateStartSpeed
export const cameraDurationMoveStart1 = 2000 * rateStartSpeed
export const cameraDelayLookStart1 = 1000 * rateStartSpeed
export const cameraDurationLookStart1 = 2000 * rateStartSpeed
export const cameraPosStart1 = new THREE.Vector3(0, 35, 1)
export const cameraLookStart1 = new THREE.Vector3(0, 25, 0)

export const cameraDelayMoveStart2 = 500 * rateStartSpeed
export const cameraDurationMoveStart2 = 2000 * rateStartSpeed
export const cameraDelayLookStart2 = 1500 * rateStartSpeed
export const cameraDurationLookStart2 = 1000 * rateStartSpeed
export const cameraPosStart2 = new THREE.Vector3(0, 6, 1)
export const cameraLookStart2 = new THREE.Vector3(0, 0, -8)

export const cameraDelayMoveStart3 = 0 * rateStartSpeed
export const cameraDurationMoveStart3 = 1000 * rateStartSpeed
export const cameraDelayLookStart3 = 0 * rateStartSpeed
export const cameraDurationLookStart3 = 1000 * rateStartSpeed

export const cameraPos3DMode = new THREE.Vector3(0, 2, 8.5)
export const cameraLook3DMode = new THREE.Vector3(0, 3.5, 0)
export const cameraZoom3DMode = 1

export const cameraPos2DModeDistanceY = 6
export const cameraPos2DModeBaseZ = 1.25
export const cameraPos2DModeOffsetZ = 2.25

export const cameraPos2DMode = new THREE.Vector3(0,
    cardsFormationTableY + cameraPos2DModeDistanceY,
    cameraPos2DModeBaseZ + cameraPos2DModeOffsetZ)
export const cameraLook2DMode = new THREE.Vector3(0, cardsFormationTableY, cameraPos2DModeBaseZ)
export const cameraZoom2DMode = 0.6

export const cameraZoomShowContent = 1.1


// Controls
export const controlsDelayEnableStart =
    100 + cameraDelayMoveStart1 + cameraDurationMoveStart1
    + cameraDelayMoveStart2 + cameraDurationMoveStart2
    + cameraDelayMoveStart3 + cameraDurationMoveStart3

export const controlsOCMaxDistance = 18
export const controlsOCMinDistance = 2

export const controlsMCMaxDistance = 12
export const controlsMCMinDistance = 1.25


// All
export const durationOpeningAnime = 100 + cameraDelayMoveStart1 + cameraDurationMoveStart1
    + cameraDelayMoveStart2 + cameraDurationMoveStart2
    + cameraDelayMoveStart3 + cameraDurationMoveStart3

