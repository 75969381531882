import { useContext, useEffect, useRef, useState } from "react"
import { ChangePointerContext, PointerStatusContext } from "src/AppContexts"
import { POINTER } from "src/interfaces"
import { isSmartPhone } from "src/utils/Utils"
import * as CONF from 'src/configs'
import * as UTILS from 'src/utils/Utils'

const isDebug = CONF.isDebug && false

/**
 * PointerStalker
 */
const PointerStalker: React.FC = () => {

    const pointerStatus = useContext(PointerStatusContext)
    const changePointer = useContext(ChangePointerContext)

    const [active, setActive] = useState(true)
    const [isAddEvent, setIsAddEvent] = useState(false)

    const stalkerElement1 = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (isAddEvent) return

        setIsAddEvent(true)

        // スマホ時は表示しない
        if (isSmartPhone() || UTILS.isIE) {
            setActive(false)
        } else {
            let translateStr = ''

            document.body.addEventListener('mousemove', (event: MouseEvent) => {
                function request() {
                    translateStr = 'translate(' + event.pageX + 'px, ' + event.pageY + 'px)'

                    if (stalkerElement1.current) {
                        stalkerElement1.current.style.transform = translateStr
                    }
                }
                requestAnimationFrame(request)
            })

            document.body.addEventListener("mouseleave", () => {
                if (isDebug) console.log("マウスカーソルが画面外へ出たので、PointerStalkerを隠します")
                changePointer(POINTER.HIDDEN, "PointerStalker")
            })

            document.body.addEventListener("mouseenter", () => {
                if (isDebug) console.log("マウスカーソルが画面内へ入ったので、PointerStalkerを表示します")
                changePointer(POINTER.IDLE, "PointerStalker")
            })
        }
    }, [isAddEvent, changePointer])

    return (
        <>
            {
                active
                &&
                <div className='pointer-stalker' >
                    <div
                        id='pointerMarkWrapper'
                        ref={stalkerElement1}
                    >
                        <div
                            id="pointerMark"
                            className={"status-" + pointerStatus}
                        />
                    </div>
                </div>
            }
        </>
    )
}

export default PointerStalker