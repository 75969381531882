import { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import VirtualLab from 'src/VirtualLab/VirtualLab'
import * as CONF from 'src/configs'

import labLogo from 'src/assets/images/thelab-logo.svg'
import { ChangePointerContext, IsOpeningEndContext, LoadingProgressContext } from 'src/AppContexts'
import { POINTER } from 'src/interfaces'
import Gtag from 'src/utils/Gtag'

const isFastStart = CONF.isDebug && true
const delayLoadFinished = isFastStart ? 100 : 1000
const durationFadeOut = 300


type Props = {
    virtualLab: VirtualLab | null
}

/** 
 * ロード画面
 */
const LoadingLayer: React.FC<Props> = ({
    virtualLab
}) => {

    const changePointer = useContext(ChangePointerContext)
    const isOpeningEndContext  = useContext(IsOpeningEndContext)
    const loadingProgress = useContext(LoadingProgressContext).loadingProgress

    const [isLayerShown, setIsLayerShown] = useState(true)
    const [isLoadFinish, setIsLoadFinish] = useState(false)

    const animeElement = useRef(null);


    useEffect(() => {
        if (loadingProgress >= 100) {
            // ロード完了
            window.setTimeout(() => {
                changePointer(POINTER.IDLE, "LoadingLayer")
                setIsLoadFinish(true)

                Gtag.onLoadFinised()
            }, delayLoadFinished)
        }
    }, [loadingProgress, changePointer])


    // start opening
    useEffect(() => {
        if (!isLoadFinish) return
        if (isOpeningEndContext.isOpeningEnd) return

        if (virtualLab) {
            if (CONF.isDebug) console.log(`オープニングAnimeをスタート`);

            setIsLayerShown(false)
            virtualLab.onStart()

            Gtag.clickStart()

            window.setTimeout(() => {
                if (CONF.isDebug) console.log("オープニングAnimeが完了")

                isOpeningEndContext.setIsOpeningEnd(true)
                virtualLab.onStartEnd()

                Gtag.onOpeningEnd()
            }, CONF.durationOpeningAnime)
        }
    }, [isLoadFinish, isOpeningEndContext, virtualLab])


    const contentClassName = isLoadFinish ?
        'loading-layer-content is-loaded' :
        'loading-layer-content is-loading'

    const barProgressStyleX = (): CSSProperties => {
        return { transform: `scaleX(${(100 - loadingProgress) * 0.01})` }
    }

    const barProgressStyleY = (): CSSProperties => {
        return { transform: `scaleY(${(100 - loadingProgress) * 0.01})` }
    }


    // // ロード完了後、ボタンが押されたらオープニングをスタートする
    // const onClick = () => {
    //     if (isLoadFinish) {
    //         if (virtualLab) {
    //             if (CONF.isDebug) console.log(`オープニングAnimeをスタート`);

    //             setIsLayerShown(false)
    //             virtualLab.onStart()

    //             Gtag.clickStart()

    //             window.setTimeout(() => {
    //                 if (CONF.isDebug) console.log("オープニングAnimeが完了")

    //                 isOpeningEndContext.setIsOpeningEnd(true)
    //                 virtualLab.onStartEnd()

    //                 Gtag.onOpeningEnd()
    //             }, CONF.durationOpeningAnime)
    //         }
    //     }
    // }


    const LoadingMessage = () => {
        if (isLoadFinish) {
            return <p className="loading-message is-loaded">Start !</p>
        }
        return <p className="loading-message is-loading">Loading <span>{loadingProgress} %</span></p>
    }


    const onMouseEnter = () => {
        if (isLoadFinish) {
            changePointer(POINTER.ONBUTTON, "LoadingLayer")
        }
    }

    const onMouseLeave = () => {
        if (isLoadFinish) {
            changePointer(POINTER.IDLE, "LoadingLayer")
        }
    }


    return (
        <CSSTransition
            in={isLayerShown}
            timeout={durationFadeOut}
            nodeRef={animeElement}
            mountOnEnter={true}
            unmountOnExit={true}
            enter={false}
            exit={true}
        >
            <div className="loading-layer" ref={animeElement} >
                <div className={contentClassName}>
                    <div className="loading-bar-wrapper">
                        <div id="loadingBar1" className="loading-bar" style={barProgressStyleX()}></div>
                        <div id="loadingBar2" className="loading-bar" style={barProgressStyleY()}></div>
                        <div id="loadingBar3" className="loading-bar" style={barProgressStyleX()}></div>
                        <div id="loadingBar4" className="loading-bar" style={barProgressStyleY()}></div>
                    </div>

                    <button
                        className="loading-content-button"
                        // onClick={() => onClick()}
                        onMouseMove={() => onMouseEnter()}
                        onMouseEnter={() => onMouseEnter()}
                        onMouseLeave={() => onMouseLeave()}
                    >
                        <figure className="loading-logo">
                            <img width='300' height='150' src={labLogo} alt="TheLabのロゴ" />
                        </figure>
                        <LoadingMessage />
                    </button>
                </div>
            </div>
        </CSSTransition>
    )
}

export default LoadingLayer