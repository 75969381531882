// Utils
export const PI2 = Math.PI * 2


export const shuffleArray = ([...array]) => {
    for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}


export const isIE = navigator.userAgent.indexOf('Trident') >= 0 || navigator.userAgent.indexOf('msie') >= 0


export function isSmartPhone() {
    if (
        navigator.userAgent.indexOf('iPhone') > 0 ||
        navigator.userAgent.indexOf('iPod') > 0 ||
        (
            navigator.userAgent.indexOf('Android') > 0 &&
            navigator.userAgent.indexOf('Mobile') > 0
        )
    ) {
        // is SP
        return true
    } else if (
        navigator.userAgent.indexOf('iPad') > 0 ||
        navigator.userAgent.indexOf('Android') > 0
    ) {
        // is Tab
        return true
    } else if (
        navigator.userAgent.indexOf('Safari') > 0 &&
        navigator.userAgent.indexOf('Chrome') === -1 &&
        typeof document.ontouchstart !== 'undefined'
    ) {
        // is Tab iOS 13 over
        return true
    }

    // is PC
    return false;
}