import * as THREE from 'three'
import * as CONF from 'src/configs'
import VLLoadingManager from './VLLoadingManager'
import Gtag from 'src/utils/Gtag'

const isDebug = CONF.isDebug && true


const kurokoSize = 2.2
const kurokoPos = { x: 4.5, y: kurokoSize / 2, z: -2.25 }

const comuSize = 2.3
const comuPos = { x: -4.5, y: comuSize / 2, z: -2 }



class VLCharactors {

    private camera: THREE.Camera
    private tempLookPos: THREE.Vector3 = new THREE.Vector3()

    private _charactors: THREE.Object3D[] = []
    get charactors(): THREE.Object3D[] { return this._charactors }

    private kurokoMesh: THREE.Mesh
    private kurokoMaterial: THREE.MeshBasicMaterial
    private kurokoTexture1: THREE.Texture
    private kurokoTexture2: THREE.Texture

    private comuMesh: THREE.Mesh
    private comuMaterial: THREE.MeshBasicMaterial
    private comuTexture1: THREE.Texture
    private comuTexture2: THREE.Texture


    constructor(camera: THREE.Camera) {
        this.camera = camera
        this.tempLookPos.copy(this.camera.position)

        const loader = VLLoadingManager.textureLoader

        // Generate Kuroko
        const kurokoGeometry = new THREE.PlaneGeometry(kurokoSize, kurokoSize)
        this.kurokoTexture1 = loader.load('/images/three/chara-kuroko.png')
        this.kurokoTexture2 = loader.load('/images/three/chara-kuroko2.png')

        this.kurokoMaterial = new THREE.MeshBasicMaterial({
            map: this.kurokoTexture1,
            side: THREE.DoubleSide,
            transparent: true
        })
        this.kurokoMaterial.alphaTest = 0.5

        this.kurokoMesh = new THREE.Mesh(kurokoGeometry, this.kurokoMaterial)
        this.kurokoMesh.position.set(kurokoPos.x, kurokoPos.y, kurokoPos.z)
        this.kurokoMesh.layers.enable(1)

        this.charactors.push(this.kurokoMesh)


        // Generate Comunicator
        const comuGeometry = new THREE.PlaneGeometry(comuSize, comuSize)
        this.comuTexture1 = loader.load('/images/three/chara-comu.png')
        this.comuTexture2 = loader.load('/images/three/chara-comu2.png')

        this.comuMaterial = new THREE.MeshBasicMaterial({
            map: this.comuTexture1,
            side: THREE.DoubleSide,
            transparent: true
        })
        this.comuMaterial.alphaTest = 0.5

        this.comuMesh = new THREE.Mesh(comuGeometry, this.comuMaterial)
        this.comuMesh.position.set(comuPos.x, comuPos.y, comuPos.z)
        this.comuMesh.layers.enable(1)

        this.charactors.push(this.comuMesh)

        // start Update
        this.update()
    }



    init(scene: THREE.Scene) {
        scene.add(this.kurokoMesh)
        scene.add(this.comuMesh)
    }



    update = () => {
        requestAnimationFrame(this.update)

        if (!this.tempLookPos.equals(this.camera.position)) {
            this.tempLookPos.copy(this.camera.position)

            this.kurokoMesh.lookAt(this.camera.position.x, kurokoPos.y, this.camera.position.z)
            this.comuMesh.lookAt(this.camera.position.x, comuPos.y, this.camera.position.z)
        }
    }


    /**
     * オブジェクトidから、クリックされたかどうか判定する。
     * キャラクターだった場合、それに応じたアクションをする
     */
    checkPointerDownByObjId(objId: number) {
        if (this.kurokoMesh.id === objId) {
            if (isDebug) console.log("黒子ちゃんがクリックされました")

            if (this.kurokoMaterial.map?.uuid === this.kurokoTexture1.uuid) {
                this.kurokoMaterial.map = this.kurokoTexture2
                this.kurokoMaterial.map.needsUpdate = true
            } else {
                this.kurokoMaterial.map = this.kurokoTexture1
                this.kurokoMaterial.map.needsUpdate = true
            }

            Gtag.clickKuroko()
        }

        if (this.comuMesh.id === objId) {
            if (isDebug) console.log("コミュニケーターがクリックされました")

            if (this.comuMaterial.map?.uuid === this.comuTexture1.uuid) {
                this.comuMaterial.map = this.comuTexture2
                this.comuMaterial.map.needsUpdate = true
            } else {
                this.comuMaterial.map = this.comuTexture1
                this.comuMaterial.map.needsUpdate = true
            }

            Gtag.clickComunicator()
        }
    }
}

export default VLCharactors