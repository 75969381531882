import * as CONF from 'src/configs'
import Gtag from 'src/utils/Gtag'
import VLScene from './VLScene'

const isDebug = CONF.isDebug && false
const isDisableRecommender = CONF.isDebug && false

const timeToRecomendStart = 15000
const intervalTimeOfRecommend = 5000
const countOfRandomRecommend = 3



/**
 * レコメンドを管理する
 */
class VLRecommender {

    private vlScene: VLScene

    private isWatch: boolean = false
    private timerWatchIdle: number = 0

    private isRecommending: boolean = false
    private timerRecommend: number = 0

    private cardsLength: number
    private countRecommend: number = 0
    private indexOfRecommend: number = 0



    constructor(vlScene: VLScene, cardsLength: number) {
        this.vlScene = vlScene
        this.cardsLength = cardsLength
    }



    /**
     * レコメンダーを起動します
     */
    init() {
        if (isDisableRecommender) return

        // レコメンドのアイドルリスナー
        document.addEventListener('pointermove', this.onUserAction)
        document.addEventListener('click', this.onUserAction)
        document.addEventListener('contextmenu', this.onUserAction)
        document.addEventListener('wheel', this.onUserAction)
        document.addEventListener('keypress', this.onUserAction)

        // windowがフォーカスを失った時
        window.addEventListener('blur', this.onBlurWindow)

        this.enableWatchIdle()
    }



    /**
     * ユーザーの操作を検知し、アイドリングをリスタートするか、レコメンドを解除します
     */
    private onUserAction = () => {
        // レコメンド状態の時、操作があれば、レコメンドを中止します
        if (this.isRecommending) {
            this.isRecommending = false
            this.stopRecommend()
        }

        // 監視状態(コンテンツを開いていない)なら、タイマーをリセットする
        if (this.isWatch) {
            if (isDebug) console.log(`アイドル状態の監視タイマーをリセットします`)

            window.clearTimeout(this.timerWatchIdle)

            this.timerWatchIdle = window.setTimeout(() => {
                this.startRecommend()
            }, timeToRecomendStart)
        }
    }



    /**
     * ユーザーフォーカスを失った時、レコメンドのintervalをキャンセルし、必要に応じてレコメンドを停止します
     */
    private onBlurWindow = () => {
        if (isDebug) console.log(`フォーカスを失いました`)

        window.clearInterval(this.timerRecommend)
        window.clearTimeout(this.timerWatchIdle)

        if (this.isRecommending) {
            this.isRecommending = false
            this.vlScene.onStopRecommend()
        }

    }



    /**
     * レコメンドを開始します。
     */
    private startRecommend() {
        if (isDebug) console.log(`一定時間が経過したのでレコメンドを開始します`)

        this.isRecommending = true
        this.indexOfRecommend = Math.floor(this.cardsLength * Math.random())

        // レコメンド開始を伝え、カードの数を取得する
        this.vlScene.onStartRecommend()

        this.recommend()

        Gtag.startRecommend()

        window.clearInterval(this.timerRecommend)

        this.timerRecommend = window.setInterval(() => {
            this.recommend()
        }, intervalTimeOfRecommend)
    }



    /**
     * レコメンドを停止します
     */
    private stopRecommend() {
        window.clearInterval(this.timerRecommend)

        this.vlScene.onStopRecommend()

        Gtag.stopRecommend()
    }



    /**
     * レコメンドします
     */
    private recommend = () => {
        this.vlScene.onRecommend(this.indexOfRecommend)

        this.indexOfRecommend -= 1
        if (this.indexOfRecommend < 0) this.indexOfRecommend = this.cardsLength - 1

        this.countRecommend++
        if (this.countRecommend >= countOfRandomRecommend) {
            this.countRecommend = 0
            this.indexOfRecommend = Math.floor(this.cardsLength * Math.random())
        }
    }



    /** 
     * アイドル状態の監視を有効化します
     */
    enableWatchIdle() {
        if (isDisableRecommender) return
        if (isDebug) console.log(`アイドル状態の監視を有効にします`)

        this.isWatch = true
    }



    /**
     * アイドル状態の監視を無効化します
     */
    disableWatchIdle() {
        if (isDisableRecommender) return
        if (isDebug) console.log(`アイドル状態の監視を無効にします`)

        this.isWatch = false
        window.clearTimeout(this.timerWatchIdle)
    }

}

export default VLRecommender