import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import Contents from 'src/models/Contents'
import Categories from 'src/models/Categories';

import VirtualLab from 'src/VirtualLab/VirtualLab';

import * as CONF from 'src/configs'
import ContentBackLayer from './ContentBackLayer';
import React from 'react';
import ContentLayer from './ContentLayer';
import { ContentDisplayedTypes, ContentStateTypes } from 'src/interfaces';
import { MatterPortView } from './ContentParts';
import Gtag from 'src/utils/Gtag';


const isDebug = CONF.isDebug && false


type Props = {
    mode: number
    contentState: ContentStateTypes
    setContentState: Dispatch<SetStateAction<ContentStateTypes>>
    virtualLab: VirtualLab | null,
}

/**
 * コンテンツを表示するロジックを担当する
 */
const ContentFrame: React.FC<Props> = ({
    mode,
    contentState,
    setContentState,
    virtualLab,
}) => {

    const [contentDisp, setContentDisp] = useState<ContentDisplayedTypes>({
        frameType: 0,
        categoryStr: "",
        classNameColor: "",
        title: "",
        summary: "",
        imagePath: "",
        texts: [],
        additions: [],
        linkPath: "",
        videoSrc: ""
    })

    const [isReady, setIsReady] = useState(false)
    const [isShown, setIsShown] = useState(false)

    const [isShownMatterPort, setIsShowmMatterPort] = useState(false)

    // アニメーション用のタイムアウトタイマー
    const timerReady = useRef(0)


    // コンテンツ表示を閉じるための関数
    const hideContent = useCallback(() => {
        if (virtualLab) {
            virtualLab.onHideContent()
        }

        Gtag.hideContent();

        setContentState({
            contentId: -1,
            isShown: false,
            delay: 0
        })
    }, [virtualLab, setContentState])


    // コンテンツidの変更を検知して、必要な情報をロードする
    useEffect(() => {
        if (isDebug) {
            console.log({
                message: "contentStateの更新を検知しました。データをロードします",
                contentState: contentState
            })
        }

        // コンテンツidに応じて情報をロード
        if (contentState.contentId > 0) {

            // コンテンツがロードされていることを確認
            const promise = Contents.init()
            promise.then(() => {
                const content = Contents.getContentById(contentState.contentId)

                // set id
                const id = content.id;
                // set FrameType
                const frameType = content.frameType
                // set Category
                const category = content.category
                const categoryStr = Categories.getCategoryStr(category)
                // set ClassName Color
                const classNameColor = ' color-' + category
                // set Title
                const title = content.title
                // set Summary
                const summary = content.summary
                // set ImagePath
                const imagePath = content.image
                // set Texts
                const texts = content.text.split(/(\n)/).map((str, index) => {
                    return (
                        < React.Fragment key={index}>
                            {str.match(/\n/) ? <br /> : str}
                        </ React.Fragment>
                    );
                })
                // set Addition
                let additions: JSX.Element[] = []
                if (content.addition !== "") {
                    additions = content.addition.split(/(\n)/).map((str, index) => {
                        return (
                            < React.Fragment key={index}>
                                {str.match(/\n/) ? <br /> : str}
                            </ React.Fragment>
                        );
                    })
                }
                // set Link
                const linkPath = content.link
                // set VideoSrc
                const videoSrc = content.videoSrc

                setContentDisp({
                    frameType: frameType,
                    categoryStr: categoryStr,
                    classNameColor: classNameColor,
                    title: title,
                    summary: summary,
                    imagePath: imagePath,
                    texts: texts,
                    additions: additions,
                    linkPath: linkPath,
                    videoSrc: videoSrc
                })

                // Gtag
                if (contentState.isShown) {
                    switch (mode) {
                        case 3:
                            Gtag.showContent3d(title, id)
                            break
                        case 2:
                            Gtag.showContent2d(title, id)
                            break
                        case 1:
                            Gtag.showContentStandard(title,id)
                            break
                    }
                }
            })
        }

        // コンテンツの表示フラグに応じて、フレームを表示する
        window.clearTimeout(timerReady.current)
        if (contentState.isShown) {
            setIsReady(true)

            timerReady.current = window.setTimeout(() => {
                setIsShown(true)
            }, contentState.delay)
        } else {
            setIsReady(false)
            setIsShown(false)
        }

    }, [mode, contentState])


    return (
        <div className="content-frame">
            {isReady &&
                <>
                    <ContentBackLayer
                        isShown={isShown}
                    />

                    <ContentLayer
                        isShown={isShown}
                        hideContent={hideContent}
                        contentData={contentDisp}
                        setIsShownMatterPort={setIsShowmMatterPort}
                    />

                    {
                        contentDisp.frameType === 3 &&
                        <MatterPortView
                            isShownMatterPort={isShownMatterPort}
                            setIsShownMatterPort={setIsShowmMatterPort}
                            linkURL={contentDisp.linkPath}
                        />
                    }
                </>
            }
        </div>
    )
}

export default ContentFrame
