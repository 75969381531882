import * as THREE from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'

import * as CONF from 'src/configs'
import * as UTILS from 'src/utils/Utils'

const isDebug = CONF.isDebug && false
const numOfLines = 100

const lineRotateMinSpeed = 0.0007
const lineRotateRangeSpeed = 0.0008

const lineReverseRate = 0.25

const lineMinRadius = 8
const lineRangeRadius = 10

const lineLongMinRadian = 0.1
const lineLongRangeRadian = 0.2

const lineWidth = 0.0022

const lineDiffXZ = 2
const lineDiffOffset = -lineDiffXZ * 0.5
const lineOffsetY = 0
const lineRangeY = 10


/**
 * 背景を回っているライン
 */
class VLLines {

    constructor(scene: THREE.Scene) {
        if (isDebug) console.log(`VLLineを生成します`);

        for (let i = 0; i < numOfLines; i++) {
            const curveLine = new CurveLine()
            scene.add(curveLine.curveLine)
        }
    }
}


/** -----------------------------------
 * CurveLine
 * -----------------------------------
 */
class CurveLine {

    private static readonly divisions = 10

    private _curveLine: Line2
    get curveLine(): Line2 { return this._curveLine }

    private isUpdate = true
    private rotateSpeed
    private isReverse


    constructor() {

        this.rotateSpeed = lineRotateMinSpeed + Math.random() * lineRotateRangeSpeed

        this.isReverse = false
        if (Math.random() < lineReverseRate) {
            this.isReverse = true
        }

        const radius = lineMinRadius + lineRangeRadius * Math.random()
        const startRadian = Math.random() * UTILS.PI2
        const longRadian = (lineLongMinRadian + lineLongRangeRadian * Math.random()) * Math.PI

        const hue = Math.random()

        const posZ = lineDiffOffset + Math.random() * lineDiffXZ * Math.random()
        const posY = lineOffsetY + lineRangeY * Math.random()
        const posX = lineDiffOffset + Math.random() * lineDiffXZ * Math.random()

        const materialColor = new THREE.Color()
        materialColor.setHSL(hue, 1, 0.8)

        const curve = new THREE.EllipseCurve(
            0, 0,
            radius, radius,
            startRadian, startRadian + longRadian,
            false,
            0
        )

        const points: number[] = [];
        let colors: number[] = [];

        const point = new THREE.Vector2()
        const tempColor = new THREE.Color()

        for (let i = 0; i < CurveLine.divisions; i++) {
            const t = i / CurveLine.divisions

            curve.getPoint(t, point)
            points.push(point.x, 0, point.y)

            tempColor.setHSL(hue, 1, 0.8 + 0.2 * t)
            colors.push(tempColor.r, tempColor.g, tempColor.b);
        }

        if (this.isReverse) {
            colors = colors.reverse()
        }


        const lineGeometry = new LineGeometry();
        lineGeometry.setPositions(points);
        lineGeometry.setColors(colors)

        const lineMaterial = new LineMaterial({
            color: 0xffffff,
            linewidth: lineWidth,
            vertexColors: true,
            alphaToCoverage: true,
            alphaTest: 0.5
        });

        this._curveLine = new Line2(lineGeometry, lineMaterial);

        this._curveLine.position.x = posX
        this._curveLine.position.y = posY
        this._curveLine.position.z = posZ

        this.update()
    }


    update = () => {
        requestAnimationFrame(this.update)
        if (this.isUpdate) {

            if (this.isReverse) {
                this.curveLine.rotation.y -= this.rotateSpeed

                if (this.curveLine.rotation.y < -360) {
                    this.curveLine.rotation.y += 360
                }
            } else {
                this.curveLine.rotation.y += this.rotateSpeed

                if (this.curveLine.rotation.y > 360) {
                    this.curveLine.rotation.y -= 360
                }
            }
            // this.curveLine.material.linewidth += 0.0001
        }
    }

}

export default VLLines