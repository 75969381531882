import React, { Dispatch, SetStateAction, useContext } from "react"
import { ChangePointerContext, IsOpeningEndContext } from "src/AppContexts"

import * as CONF from 'src/configs'
import { POINTER } from "src/interfaces"
import Gtag, { LABEL } from "src/utils/Gtag"
import VirtualLab from "src/VirtualLab/VirtualLab"

type Props = {
    mode: number,
    setMode: Dispatch<SetStateAction<number>>
    prevMode: number
    setPrevMode: Dispatch<SetStateAction<number>>
    virtualLab: VirtualLab | null
}

const HeaderButton: React.FC<Props> = ({
    mode,
    setMode,
    prevMode,
    setPrevMode,
    virtualLab
}) => {

    const changePointer = useContext(ChangePointerContext)
    const isOpeningEnd = useContext(IsOpeningEndContext).isOpeningEnd


    const buttonClick = () => {
        if (CONF.isDebug) console.log("HeaderButton: ヘッダーボタンがクリックされた");

        // オープニングが終わっていないなら、処理をしない
        if (!isOpeningEnd) return

        if (virtualLab) {
            if (mode !== 1) {
                setPrevMode(mode)
                virtualLab.onChangeMode(1)
                setMode(1)
                Gtag.clickHeaderModeButton(LABEL.TO_STANDARD)
            } else {
                switch (prevMode) {
                    case 3:
                        virtualLab.onChangeMode(3)
                        setMode(3)
                        Gtag.clickHeaderModeButton(LABEL.TO_3D)
                        break
                    case 2:
                        virtualLab.onChangeMode(2)
                        setMode(2)
                        Gtag.clickHeaderModeButton(LABEL.TO_2D)
                        break
                }
            }
        }
    }


    const classNameIsOpening = isOpeningEnd ? "is-opening-done" : "is-opening"


    return (
        <div className={`header-button ${classNameIsOpening}`}>
            <button
                className={`header-button__inner ${(mode === 1) ? "is-open" : ""}`}
                onClick={buttonClick}
                onMouseMove={() => changePointer(POINTER.ONBUTTON, "HeaderButton")}
                onMouseEnter={() => changePointer(POINTER.ONBUTTON, "HeaderButton")}
                onMouseLeave={() => changePointer(POINTER.IDLE, "HeaderButton")}
            >
                <div id="headerButtonBar1" className="header-button-bar"></div>
                <div id="headerButtonBar2" className="header-button-bar"></div>
                <div id="headerButtonBar3" className="header-button-bar"></div>
            </button>
        </div>
    )
}

export default HeaderButton