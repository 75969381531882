import * as THREE from 'three'
import VLCard from './VLCard'
import VLCardsGroup from './VLCardsGroup'
import VLScene from './VLScene'

import * as CONF from 'src/configs'
import * as UTILS from 'src/utils/Utils'
import { POINTER } from 'src/interfaces'
import VLCharactors from './VLCharactors'

const isDebugMouseMove = CONF.isDebug && false
const isDebugHover = CONF.isDebug && false
const isDebugClick = CONF.isDebug && false


/**
 * クリック関係の処理を管理するクラスです
 */
class VLPointer {

    private vlScene: VLScene
    private canvasElement: HTMLCanvasElement
    private camera: THREE.Camera
    private vlCardsGroup: VLCardsGroup
    private vlCharactors: VLCharactors

    private isActive = false
    private isSPIE = false

    private pointer: THREE.Vector2 = new THREE.Vector2()
    private raycaster: THREE.Raycaster = new THREE.Raycaster()

    private intersectVLCards: THREE.Intersection[] = []

    private intersectVLCard: VLCard | null = null
    private tempHoverVLCard: VLCard | null = null
    private tempActiveVLCard: VLCard | null = null

    private isClick = false

    private timerChangeMode: number = 0

    private changePointer: (status: number, from: string) => void



    /**
     * Constructor
     */
    constructor(
        vlScene: VLScene,
        canvasElement: HTMLCanvasElement,
        camera: THREE.Camera,
        vlCardsGroup: VLCardsGroup,
        vlCharactors: VLCharactors,
        changePointer: (status: number, from: string) => void
    ) {
        this.vlScene = vlScene
        this.canvasElement = canvasElement
        this.camera = camera
        this.vlCardsGroup = vlCardsGroup
        this.vlCharactors = vlCharactors
        this.changePointer = changePointer

        this.isSPIE = UTILS.isSmartPhone() || UTILS.isIE

        // Raycaster Settings
        this.raycaster.near = CONF.cameraNear
        this.raycaster.far = CONF.cameraFar
        this.raycaster.layers.set(1)

        // add pointer move listener
        this.canvasElement.addEventListener('pointermove', this.onPointerMove)

        // add click event listener
        this.canvasElement.addEventListener('pointerdown', this.onPointerDown)
        this.canvasElement.addEventListener('pointerup', this.onPointerUp)

        // start animation
        this.update()
    }



    /**
     * ループ。
     * カーソルの位置をリアルタイムで取得し、ホバー判定をする。
     * ホバー反応の処理なので、スマホはループしない
     */
    private update = () => {
        if (this.isSPIE) return

        requestAnimationFrame(this.update)

        this.checkIntersectCard()
        this.reflectHoverCard()
    }



    /**
     * マウスポインター上にあるカードを取得し、あればメンバー変数に入れる
     * TODO: ボトルネックになりやすいので、できるかぎり最適化したい
     */
    private checkIntersectCard() {

        if (!this.isActive) return

        this.intersectVLCard = null
        this.raycaster.setFromCamera(this.pointer, this.camera)

        // カードグループの中から、raychasterが一致するものがないか探す
        this.intersectVLCards = this.raycaster.intersectObjects(
            this.vlCardsGroup.cardsGroup.children,
            true
        );

        // 交差したobjmeshから、idを比較し、objが存在したらintersectVLCardに入れる
        for (let i = 0; i < this.intersectVLCards.length; i++) {

            const parentObj = this.intersectVLCards[i].object.parent

            if (parentObj) {
                this.intersectVLCard = this.vlCardsGroup.getVLCardByThreeObjectId(parentObj.id)

                if (this.intersectVLCard) break
            }
        }
    }



    /**
     * カードがホバーしているかを検証し、必要に応じてカードの色を変えるようにする
     */
    private reflectHoverCard() {

        if (!this.isActive) return

        if (this.intersectVLCard) {
            if (this.tempHoverVLCard) {
                if (this.intersectVLCard.cardObj.id !== this.tempHoverVLCard.cardObj.id) {
                    if (isDebugHover) console.log(`ホバーされていたオブジェクトが変更されました`);
                    this.tempHoverVLCard.onPointerLeave()
                    this.intersectVLCard.onPointerEnter()
                    this.tempHoverVLCard = this.intersectVLCard
                }
            } else {
                if (isDebugHover) console.log(`新しくホバーのオブジェが見つかりました`);
                this.intersectVLCard.onPointerEnter()
                this.tempHoverVLCard = this.intersectVLCard
                this.changePointer(POINTER.ONBUTTON, "VLPointer")
            }
        } else {
            if (this.tempHoverVLCard) {
                if (isDebugHover) console.log(`ホバーが解除されました`);
                this.tempHoverVLCard.onPointerLeave()
                this.tempHoverVLCard = null
                this.tempActiveVLCard = null
                this.changePointer(POINTER.IDLE, "VLPointer")
            }
        }
    }



    /**
     * ポインターの座標を再計算する
     */
    private computePointerPosition = (event: MouseEvent) => {

        this.pointer.x = ((event.clientX - this.canvasElement.offsetLeft) / this.canvasElement.offsetWidth) * 2 - 1
        this.pointer.y = -((event.clientY - this.canvasElement.offsetTop) / this.canvasElement.offsetHeight) * 2 + 1

        if (isDebugMouseMove) console.log({ message: `ポインタの位置を更新します`, pointer: this.pointer })
    }



    /**
     * Charactorsがクリックされたか判定する
     */
    private checkIntersectCharactors() {
        this.raycaster.setFromCamera(this.pointer, this.camera)

        const charactors = this.vlCharactors.charactors
        const intersectObjs = this.raycaster.intersectObjects(charactors, false);

        for (let i = 0; i < intersectObjs.length; i++) {
            this.vlCharactors.checkPointerDownByObjId(intersectObjs[i].object.id)
        }
    }



    /**
     * ポインタが動いた時、ポインタの座標を再計算する
     * 再計算された座標は、UPDATEでのホバーされているカードの判定に使われる
     * ただしスマホ時はホバーが存在しないため再計算をしない。
     */
    private onPointerMove = (event: MouseEvent) => {

        if (!this.isActive) return

        // ポインタが動いたので、座標を再計算
        if (!this.isSPIE) this.computePointerPosition(event)

        if (this.isClick) {
            this.isClick = false
            // スマホ時はhoverが存在しないので、明示的に色を変えてやる
            if (this.isSPIE && this.tempActiveVLCard) {
                this.tempActiveVLCard.onPointerLeave()
            }
            this.tempHoverVLCard = null
            this.tempActiveVLCard = null
        }
    }



    /**
     * Down時に、座標を再計算して、重なっているカードをチェックし、選択されたオブジェクトを保持する
     */
    private onPointerDown = (event: MouseEvent) => {

        if (!this.isActive) return

        // 左クリック以外の場合、処理を進めない
        if (event.button !== 0) return

        event.preventDefault()

        this.computePointerPosition(event)
        this.checkIntersectCard()

        // ポインター上にカードがあれば、そのカードをアクティブ状態にし、保持する
        if (this.intersectVLCard) {
            if (isDebugClick) console.log(`カードがアクティブになりました: ${this.intersectVLCard.cardObj.id}`)

            if (this.isSPIE) {
                // スマホ時は、ホバーの色をタップ中として使う
                this.intersectVLCard.onPointerEnter()
            } else {
                this.intersectVLCard.onPointerActive()
            }

            // もし、すでにアクティブなカードがあれば、明示的に色を変えて、リリースする
            if (this.tempActiveVLCard) {
                this.tempActiveVLCard.onPointerLeave()
                this.tempActiveVLCard = null
            }
            this.tempActiveVLCard = this.intersectVLCard

            this.isClick = true
        }

        // キャラクターがクリックされたか判定する
        this.checkIntersectCharactors()
    }



    /**
     * Up時に、Down時に取得したオブジェクトと、現在マウス上にあるオブジェクトを比較し、同じならクリックとして処理する
     */
    private onPointerUp = (event: MouseEvent) => {

        if (!this.isActive) return

        if (event.button !== 0) return

        event.preventDefault()

        this.computePointerPosition(event)
        this.checkIntersectCard()

        if (this.intersectVLCard && this.tempActiveVLCard) {
            if (this.intersectVLCard.cardObj.id === this.tempActiveVLCard.cardObj.id) {

                if (this.isClick) {
                    // コンテンツを表示する
                    this.vlScene.showContent(this.intersectVLCard)

                    // ポインターストーカーをIdleに
                    this.changePointer(POINTER.IDLE, "VLPointer")
                }
            }
        }

        // SP時、明示的に色を変えてやる
        if (this.isSPIE) {
            if (this.tempActiveVLCard) {
                this.tempActiveVLCard.onPointerLeave()
            }
        }

        this.tempActiveVLCard = null
        this.isClick = false
    }


    /**
     * オープニングアニメを開始時に呼ばれる
     */
    onStart() {
        this.isActive = false
    }


    /**
     * オープニングアニメ完了時に呼ばれる
     */
    onStartEnd() {
        this.isActive = true
    }


    /**
     * コンテンツを表示する時に呼ばれる
     */
    onShowContent() {
        this.isActive = false
    }


    /**
     * コンテンツを非表示にする時に呼ばれる
     */
    onHideContent() {
        this.isActive = true
    }


    /**
     * モード変更時に呼ばれる
     */
    onChangeMode(mode: number) {
        this.isActive = false
        window.clearTimeout(this.timerChangeMode)

        // モード2, 3への変更なら、移動完了後に操作できるようにする
        if (mode === 3 || mode === 2) {
            this.timerChangeMode = window.setTimeout(() => {
                this.isActive = true
            }, CONF.durationChangeMode)
        }
    }


    onStartRecommend() {
        this.isActive = false
    }


    onStopRecommend() {
        this.isActive = true
    }
}

export default VLPointer