import * as THREE from 'three'
import { MapControls, OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import * as CONF from 'src/configs'
import * as UTILS from 'src/utils/Utils'
import Gtag from 'src/utils/Gtag'

const isDebug = CONF.isDebug && true

const judgeDragRange = 50


class VLControls {

    private mode: number
    private canvasElement: HTMLCanvasElement

    private isActive: boolean = false

    private orbitControls: OrbitControls
    private mapControls: MapControls

    private updateTimer: number = 0
    private enableTimer: number = 0

    private isMouseDownPos = { x: 0, y: 0 }
    private isMouseRightDownPos = { x: 0, y: 0 }
    private tempPointerEvents: PointerEvent[] = []


    constructor(mode: number, camera: THREE.Camera, canvasElement: HTMLCanvasElement) {

        this.mode = mode
        this.canvasElement = canvasElement

        this.orbitControls = new OrbitControls(camera, canvasElement)
        this.orbitControls.enabled = false
        this.orbitControls.maxDistance = CONF.controlsOCMaxDistance
        this.orbitControls.minDistance = CONF.controlsOCMinDistance
        this.orbitControls.enableDamping = true
        this.orbitControls.dampingFactor = 0.2
        this.orbitControls.rotateSpeed = 0.5
        this.orbitControls.panSpeed = 0.5
        this.orbitControls.zoomSpeed = 0.5

        this.mapControls = new MapControls(camera, canvasElement)
        this.mapControls.enabled = false
        this.mapControls.maxDistance = CONF.controlsMCMaxDistance
        this.mapControls.minDistance = CONF.controlsMCMinDistance
        this.mapControls.enableDamping = true
        this.mapControls.dampingFactor = 0.2
        this.mapControls.rotateSpeed = 0.5
        this.mapControls.panSpeed = 1.25
        this.mapControls.zoomSpeed = 1.2

        // update
        this.update()


        // add click event listener
        this.canvasElement.addEventListener('pointerdown', this.onPointerDown)
        this.canvasElement.addEventListener('pointerup', this.onPointerUp)

        // pc時はピンチとスクロールの測定をせず、スマホ時は右クリックの測定をしない
        if (UTILS.isSmartPhone()) {
            this.canvasElement.addEventListener('pointerdown', this.onPointerDownSp)
            this.canvasElement.addEventListener('pointerup', this.onPointerUpSp)
        } else {
            this.canvasElement.addEventListener('pointerdown', this.onPointerDownRight)
            this.canvasElement.addEventListener('pointerup', this.onPointerUpRight)
            this.canvasElement.addEventListener('wheel', this.onWheelAction)
        }
    }


    private update = () => {
        requestAnimationFrame(this.update)

        if (this.orbitControls.enabled) this.orbitControls.update()
        if (this.mapControls.enabled) this.mapControls.update()
    }


    /**
     * 全てのコントロールを無効にします
     */
    disableAllControls() {
        if (isDebug) console.log(`VLControls: 全てのコントロールを無効にします`);

        this.isActive = false

        // コントロール有効化のタイマーがあればクリアする
        window.clearTimeout(this.enableTimer)
        window.clearTimeout(this.updateTimer)

        this.orbitControls.enabled = false
        this.mapControls.enabled = false
    }


    /**
     * 現在のモードに応じてコントロールを有効にします
     */
    enableControl(delay: number = 0) {
        if (isDebug) console.log(`VLControls: コントロール(モード:${this.mode})を${delay}秒後に有効にします`)

        // コントロール有効化のタイマーがあればクリアする
        window.clearTimeout(this.enableTimer)

        this.enableTimer = window.setTimeout(() => {
            if (CONF.isDebug) console.log(`VLControls: コントロール(モード:${this.mode})が有効になりました`)

            this.isActive = true

            switch (this.mode) {
                case 3:
                    this.orbitControls.enabled = true
                    break
                case 2:
                    this.mapControls.enabled = true
                    break
            }
        }, delay)
    }



    /**
     * カメラポジションと、ターゲットの位置から、コントロールをリセットします
     */
    updateControls(
        position: THREE.Vector3,
        target: THREE.Vector3,
        delay: number = 0
    ) {

        if (isDebug) console.log(`CameraControlsの情報を${delay}秒後にアップデートします`);

        window.clearTimeout(this.updateTimer)

        this.updateTimer = window.setTimeout(() => {

            this.mapControls.object.position.copy(position)
            this.mapControls.target.copy(target)

            this.orbitControls.object.position.copy(position)
            this.orbitControls.target.copy(target)

            this.mapControls.update()
            this.orbitControls.update()

            if (isDebug) console.log(`CameraControlsの情報をアップデートしました`);

        }, delay)

    }



    private onPointerDown = (event: PointerEvent) => {
        if (!this.isActive) return

        if (event.button === 0) {
            this.isMouseDownPos = { x: event.clientX, y: event.clientY }
        }

    }


    private onPointerUp = (event: PointerEvent) => {
        if (!this.isActive) return

        if (event.button === 0) {
            const durationX = Math.abs(this.isMouseDownPos.x - event.clientX)
            const durationY = Math.abs(this.isMouseDownPos.y - event.clientY)

            if (durationX > judgeDragRange || durationY > judgeDragRange) {
                this.canvasElement.removeEventListener('pointerdown', this.onPointerDown)
                this.canvasElement.removeEventListener('pointerup', this.onPointerUp)

                Gtag.firstDragVirtual()
            }
        }
    }


    private onPointerDownRight = (event: PointerEvent) => {
        if (!this.isActive) return

        if (event.button === 2) {
            this.isMouseRightDownPos = { x: event.clientX, y: event.clientY }
        }
    }


    private onPointerUpRight = (event: PointerEvent) => {
        if (!this.isActive) return

        if (event.button === 2) {
            const durationX = Math.abs(this.isMouseRightDownPos.x - event.clientX)
            const durationY = Math.abs(this.isMouseRightDownPos.y - event.clientY)

            if (durationX > judgeDragRange || durationY > judgeDragRange) {
                this.canvasElement.removeEventListener('pointerdown', this.onPointerDownRight)
                this.canvasElement.removeEventListener('pointerup', this.onPointerUpRight)

                Gtag.firstDragRightVirtual()
            }
        }
    }


    private onWheelAction = () => {
        if (!this.isActive) return

        Gtag.firstScrollVirtual()
        this.canvasElement.removeEventListener('wheel', this.onWheelAction)
    }


    private onPointerDownSp = (event: PointerEvent) => {
        if (!this.isActive) return

        this.tempPointerEvents.push(event)
    }


    private onPointerUpSp = (event: PointerEvent) => {
        if (!this.isActive) return

        // もし、ポインターの数が1より大きいなら、ピンチしたと判定
        if (this.tempPointerEvents.length > 1) {
            this.canvasElement.removeEventListener('pointerdown', this.onPointerDownSp)
            this.canvasElement.removeEventListener('pointerup', this.onPointerUpSp)

            Gtag.firstPinchVirtual()
        }

        // 離されたポインターを削除する
        for (var i = 0; i < this.tempPointerEvents.length; i++) {
            if (this.tempPointerEvents[i].pointerId === event.pointerId) {
                this.tempPointerEvents.splice(i, 1);
                break;
            }
        }
    }




    /**
     * モード変更時に呼ばれる
     */
    onChangeMode(mode: number) {
        this.mode = mode
        this.disableAllControls()

        this.enableControl(CONF.durationChangeMode + 100)
    }



}

export default VLControls

