export type ContentStateTypes = {
    contentId: number
    isShown: boolean
    delay: number
}

export type ContentType = {
    id: number,
    frameType: number,
    category: number,
    isStandard: boolean,
    title: string,
    summary: string,
    thumb: string,
    image: string,
    text: string,
    addition: string,
    link: string,
    videoSrc: string
}

export type ContentDisplayedTypes = {
    frameType: number
    categoryStr: string
    classNameColor: string
    title: string
    summary: string
    imagePath: string
    texts: JSX.Element[]
    additions: JSX.Element[]
    linkPath: string
    videoSrc: string
}

export type CategoryTypes = {
    id: number
    isStandard: boolean
    text: string
}

export const POINTER = {
    HIDDEN: 99,
    DISABLE: 0,
    IDLE: 1,
    ONCANCEL: 2,
    ONBUTTON: 3,
    ONANCHOR: 4
} as const
