import * as CONF from 'src/configs'
import { ContentType } from 'src/interfaces'

const isDebug = CONF.isDebug && true

const isDuplicateIdCheck = CONF.isDebug && true
const isLoadingLog = CONF.isDebug && false
const isCheckImage = CONF.isDebug && false

const jsonPath = `./contents/contents.json?v=${CONF.version}`
const thumbsDir = './contents/'
const imagesDir = './contents/'


class Contents {

    private static xhr = new XMLHttpRequest()
    private static promise: Promise<unknown> | null = null

    private static contents: ContentType[] = []


    /** Contentsを初期化する */
    static init(): Promise<unknown> {

        if (Contents.promise) {
            return Contents.promise
        }

        const xhr = Contents.xhr

        Contents.promise = new Promise((resolve, reject) => {

            // is loaded check
            if (xhr.readyState === 4) {
                if (isDebug) console.log(`重複してinitされています。すでにコンテンツをロード済みです`)
                resolve("すでにコンテンツをロード済みです")
                return
            }

            // load
            if (isDebug) console.log(`コンテンツのロードを開始`)
            Contents.load(xhr, resolve, reject)
        })

        return Contents.promise
    }



    /**
     * コンテンツをロードする
     */
    private static load(
        xhr: XMLHttpRequest,
        resolve: (value: unknown) => void,
        reject: (reason?: any) => void
    ) {

        xhr.open("GET", jsonPath, true)

        xhr.onerror = () => {
            reject('コンテンツのロードに失敗しました')
        }

        xhr.onload = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    const responseText = xhr.responseText
                    let jsonArray: ContentType[] = JSON.parse(responseText)

                    // ロードしたjsonを妥当性をチェックしつつデータとしてセットする
                    Contents.setContents(jsonArray)

                    resolve("コンテンツのロード完了")
                } else {
                    reject('コンテンツのロードに失敗')
                }
            }
        }

        xhr.send(null)
    }



    /**
     * ロードしたjsonファイルから、コンテンツのデータを生成します
     */
    private static setContents(jsonArray: ContentType[]) {

        const duplicateIdCheck: number[] = []

        for (let i = 0; i < jsonArray.length; ++i) {
            const contentObj = jsonArray[i]

            // 妥当性チェック
            const id = Number(contentObj.id)
            if (isNaN(id)) {
                if (isLoadingLog) console.error(`jsonArray${i}、idが不正です。${id}`)
                continue
            }
            if (isDuplicateIdCheck) {
                if (duplicateIdCheck.includes(id)) {
                    console.error(`index: ${i}で、id: ${id}が重複しています`)
                    continue
                }
                duplicateIdCheck.push(id)
            }

            const frameType = Number(contentObj.frameType)
            if (isNaN(frameType) || !(frameType === 1 || frameType === 2 || frameType === 3)) {
                if (isLoadingLog) console.error(`Contents: jsonArray${i}、frameTypeが不正です。${frameType} スキップします`)
                continue
            }

            const category = Number(contentObj.category)
            if (isNaN(category)) {
                if (isLoadingLog) console.error(`Contents: jsonArray${i}、frameTypeが不正です。${category} スキップします`)
                continue
            }

            // check isStandard -- trueならStandardモードの一覧として表示
            let isStandard: boolean | undefined = contentObj.isStandard
            if (isStandard == null) {
                isStandard = true
            }

            const title = contentObj.title
            if (!title) {
                if (isLoadingLog) console.error(`Contents: jsonArray${i}、Titleが不正です。${title} スキップします`)
                continue
            }

            // check summary
            let summary = contentObj.summary
            if (!summary) {
                summary = ""
            }

            let text = contentObj.text
            if (!text) text = ""

            // set and check addition
            let addition = contentObj.addition
            if (!addition) addition = ""

            const thumb = thumbsDir + contentObj.thumb
            if (!thumb) {
                if (isLoadingLog) console.error(`Contents: jsonArray${i}、Thumbが不正です。${thumb} スキップします`)
                continue
            }
            if (isCheckImage) this.checkImagesExist(thumb, id)

            // image URL
            const image = imagesDir + contentObj.image
            if (!image) {
                if (isLoadingLog) console.error(`Contents: jsonArray${i}、ImageURLが不正です。${image} スキップします`)
                continue
            }
            if (isCheckImage) this.checkImagesExist(image, id)

            const link = contentObj.link
            if (!link) {
                if (isLoadingLog) console.error(`Contents: jsonArray${i}、リンク先が不正です。${link} スキップします`)
                continue
            }

            // set videoSrc
            let videoSrc = contentObj.videoSrc
            if (!videoSrc) {
                if (frameType === 2) {
                    if (isLoadingLog) console.error(`Contents: jsonArray${i}、videoSrcは存在しません。スキップします`)
                    continue
                }
                videoSrc = ""
            }

            // コンテンツオブジェの生成
            const content: ContentType = {
                "id": id,
                "frameType": frameType,
                "category": category,
                "isStandard": isStandard,
                "title": title,
                "summary": summary,
                "text": text,
                "addition": addition,
                "thumb": thumb,
                "image": image,
                "link": link,
                "videoSrc": videoSrc
            }

            if (isLoadingLog) console.log({ Contents: `ContentObj[${i}]のロードが完了しました`, content: content })
            Contents.contents.push(content)
        }

        if (isDebug) console.log({ Contents: `全てのコンテンツのロードが完了しました`, contents: Contents.contents })
    }


    private static checkImagesExist(url: string, id: number) {
        const image = new Image()
        image.onload = () => console.log(`画像存在チェック: id:${id}のurl:${url}は存在します`)
        image.onerror = () => console.error(`画像存在チェック: id:${id}のurl:${url}が見つかりません`)
        image.src = url
    }


    private static checkLoaded() {
        console.assert(Contents.xhr.readyState === 4, `初期化されていません。先にinitを実行してください`)
    }


    static getContentsByCategoryId(categoryId: number): ContentType[] {
        if (isDebug) Contents.checkLoaded()

        const categoryContents: ContentType[] = []

        for (let i = 0; i < Contents.contents.length; i++) {
            if (Contents.contents[i].category === categoryId) {
                categoryContents.push(Contents.contents[i])
            }
        }

        return categoryContents
    }


    static getContents(): ContentType[] {
        if (isDebug) Contents.checkLoaded()

        return Contents.contents
    }


    static getContentById(contentId: number) {
        if (isDebug) Contents.checkLoaded()

        for (let i = 0; i < Contents.contents.length; i++) {
            if (Contents.contents[i].id === contentId) {
                return Contents.contents[i]
            }
        }

        if (isDebug) console.error(`コンテンツid: ${contentId}が見つかりませんでした`)
        return Contents.contents[0]
    }


    static getContentsLength(): number {
        if (isDebug) Contents.checkLoaded()

        return Contents.contents.length
    }

}

export default Contents