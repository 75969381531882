import { CategoryTypes } from "src/interfaces"

/**
 * カテゴリーを管理するクラス
 * isStandardはスタンダードモードのメニューバーに表示するかどうか
 */
class Categories {

    /**
     * isStandardはスタンダードモードのメニューバーに表示するかどうか
     */
    static readonly categories: CategoryTypes[] = [
        { id: 1, isStandard: true, text: "3Dで体験するThe Lab." },
        { id: 2, isStandard: true, text: "展示紹介ムービー" },
        { id: 3, isStandard: true, text: "コミュニケーター活動紹介" },
        { id: 4, isStandard: true, text: "The Lab. フロア紹介" },
        { id: 5, isStandard: true, text: "参画 企業・大学 サイト" },
        { id: 6, isStandard: true, text: "NCC(ニューコンビネーションコーディネート)" },
        { id: 7, isStandard: true, text: "イベント情報" }
    ]


    static getCategoryStr(categoryId: number): string {
        for (let i = 0; i < Categories.categories.length; i++) {
            if (Categories.categories[i].id === categoryId) {
                return Categories.categories[i].text
            }
        }
        return ""
    }


    static getClassNameCategoryColor(categoryId: number) {
        return "type-" + categoryId
    }

}

export default Categories
