import * as THREE from 'three'
import * as TWEEN from '@tweenjs/tween.js'
import VLCard from './VLCard'
import * as CONF from 'src/configs'
import * as UTILS from 'src/utils/Utils'
import Contents from 'src/models/Contents'
import * as VLCardsFormations from './VLCardsFormations'
import { shuffleArray } from 'src/utils/Utils'

// カードを順番通りに並べる（本番時はシャッフル）
const isSortOrder = CONF.isDebug && true




// 3Dモードでの回転速度(radian)
const speedRotate = 0.0005


class VLCardsGroup {

    vlCards: VLCard[] = []

    private _cardsGroup: THREE.Group
    get cardsGroup(): THREE.Group { return this._cardsGroup }

    private randomFormations: THREE.Object3D[] = []
    private sphereFormations: THREE.Object3D[] = []
    private helixFormations: THREE.Object3D[] = []
    private tableFormations: THREE.Object3D[] = []

    private cardsGroupTween = new TWEEN.Group()

    private isRotate: boolean


    /**
     * コンストラクター
     */
    constructor() {

        // カード情報をロードし、カードを生成する
        let contents = Contents.getContents()
        // 順番をシャッフルする
        if (!isSortOrder) contents = shuffleArray(contents)

        const numOfCards = contents.length

        for (let i = 0; i < numOfCards; i++) {
            const card = new VLCard(contents[i].id, contents[i].thumb)
            this.vlCards.push(card)
        }

        // カードグループを作成する
        this._cardsGroup = new THREE.Group()
        this.vlCards.forEach((vlCard: VLCard) => {
            const cardObj: THREE.Group = vlCard.cardObj
            this.cardsGroup.add(cardObj)
        })

        this.isRotate = false

        this.randomFormations = VLCardsFormations.generateRandomFormations(numOfCards)
        this.sphereFormations = VLCardsFormations.generateSphereFormations(numOfCards)
        this.helixFormations = VLCardsFormations.generateHelixFormations(numOfCards)
        this.tableFormations = VLCardsFormations.generateTableFormations(numOfCards)

        // 初期フォーメーションへ
        this.cardsGroup.position.copy(CONF.cardsGroupPosInit)
        this.cardsGroup.rotation.y = CONF.cardsGroupRotationYInit

        this.moveCards(this.randomFormations, 0, 0, false)

        // start anime
        this.update()
    }



    private update = () => {
        requestAnimationFrame(this.update)

        this.cardsGroupTween.update()

        if (this.isRotate) {
            this.cardsGroup.rotation.y += speedRotate
            if (this.cardsGroup.rotation.y > UTILS.PI2) {
                this.cardsGroup.rotation.y -= UTILS.PI2
            }
        }

    }



    /**
     * カードグループの回転をTweenでリセットする
     */
    private resetCardsGroupRotate(duration: number, isRotateOnComplete: boolean) {
        this.cardsGroupTween.removeAll()

        new TWEEN.Tween(this.cardsGroup.rotation, this.cardsGroupTween)
            .to({ x: 0, y: 0, z: 0 }, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onComplete(() => this.isRotate = isRotateOnComplete)
            .start()
    }



    /**
     * カードを動かします。
     */
    private moveCards(
        formation: THREE.Object3D[],
        duration: number,
        durationRange: number,
        isSwingOnComplete: boolean
    ) {

        const numOfCards = this.vlCards.length

        const isRandom = durationRange > 0
        let moveDuration = duration

        let card: VLCard
        let target: THREE.Object3D
        for (let i = 0; i < numOfCards; i++) {
            card = this.vlCards[i]
            target = formation[i].clone()

            if (isRandom) {
                moveDuration = duration + durationRange * Math.random()
            }

            card.moveTo(target.position, target.rotation.toVector3(), moveDuration, isSwingOnComplete)
        }
    }



    /**
     * スタート時に呼ばれる
     */
    onStart() {

        // move cards group
        new TWEEN.Tween(this.cardsGroup.position, this.cardsGroupTween)
            .to({ y: 0 }, CONF.cardsgroupDurationMoveStart1)
            .delay(CONF.cardsgroupDelayMoveStart1)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start()

        // rotate cards group
        new TWEEN.Tween(this.cardsGroup.rotation, this.cardsGroupTween)
            .to({ y: 0 }, CONF.cardsgroupDurationRotateStart1)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => this.isRotate = true)
            .start()

        // transform cards formations
        this.moveCards(
            this.sphereFormations,
            CONF.cardsDurationStart1,
            CONF.cardsDurationRangeStart1,
            false
        )

        window.setTimeout(() => {
            this.moveCards(this.helixFormations,
                CONF.cardsDurationStart2,
                CONF.cardsDurationRangeStart2,
                true)
        }, CONF.cardsDelayStart2)
    }



    /**
     * 3Dモードのフォーメーションにカードを変える
     */
    transform3DFormation() {
        this.isRotate = false
        this.resetCardsGroupRotate(CONF.durationChangeMode, true)

        this.moveCards(this.helixFormations, CONF.cardsMinDurationMove, CONF.cardsDurationRangeMove, true)
    }



    /**
     * 2Dモードのフォーメーションにカードを変える
     */
    transform2DFormation() {
        this.isRotate = false
        this.resetCardsGroupRotate(CONF.durationChangeMode, false)

        this.moveCards(this.tableFormations, CONF.cardsMinDurationMove, CONF.cardsDurationRangeMove, false)
    }



    /**
     * Three jsのオブジェクトIDから、一致するVLCardがあれば返す
     */
    getVLCardByThreeObjectId(id: number): VLCard | null {
        let vlCard: VLCard | null = null
        for (let i = 0; i < this.vlCards.length; i++) {
            if (id === this.vlCards[i].cardObj.id) {
                vlCard = this.vlCards[i]
                break
            }
        }

        return vlCard
    }



    /**
     * コンテンツIDから一致するVLCardを見つけて返す
     */
    getVLCardByContentId(contentId: number): VLCard | null {
        let vlCard: VLCard | null = null
        for (let i = 0; i < this.vlCards.length; i++) {
            if (contentId === this.vlCards[i].contentId) {
                vlCard = this.vlCards[i]
                break
            }
        }

        return vlCard
    }



    onShowContent() {
        this.isRotate = false
    }


    onHideContent(mode: number) {
        switch (mode) {
            case 3:
                this.isRotate = true
                break
            case 2:
                this.isRotate = false
        }
    }



    onStartRecommend() {
        this.isRotate = false
    }



    onStopRecommend(mode: number) {
        switch (mode) {
            case 3:
                this.isRotate = true
                break
            case 2:
                this.isRotate = false
        }
    }


}

export default VLCardsGroup