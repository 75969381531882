import { Dispatch, SetStateAction, useContext } from "react"
import { ChangePointerContext } from "src/AppContexts"
import { CategoryTypes, POINTER } from "src/interfaces"
import Categories from "src/models/Categories"

type Props = {
    currentCategoryId: number
    setCurrentCategoryId: Dispatch<SetStateAction<number>>
}


/**
 * スタンダードモードで表示されるタブのコンテナ
 */
const StandardTabs: React.FC<Props> = ({
    currentCategoryId,
    setCurrentCategoryId
}) => {

    const changePointer = useContext(ChangePointerContext)

    const handleClick = (categoryId: number) => {
        setCurrentCategoryId(categoryId)
    }

    const categories = Categories.categories
    const tabItems = categories.map((category: CategoryTypes) => {
        if (category.isStandard) {
            return (
                <Tab
                    key={category.id}
                    id={category.id}
                    text={category.text}
                    isActive={category.id === currentCategoryId}
                    onClick={handleClick}
                />
            )
        }
        return null
    })

    return (
        <div className="standard-tabs">
            <div className="standard-tabs__inner">

                {tabItems}

                <a
                    className="tab tab--link custom-kc"
                    href="https://kc-i.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseMove={() => changePointer(POINTER.ONANCHOR, "StandardTabs")}
                    onMouseEnter={() => changePointer(POINTER.ONANCHOR, "StandardTabs")}
                    onMouseLeave={() => changePointer(POINTER.IDLE, "StandardTabs")}
                >
                    <span>ナレッジキャピタル公式サイト</span>
                </a>

            </div>
        </div>
    )
}


/* Tab ----------------------------------- */
type TabProps = {
    id: number
    text: string
    isActive: boolean
    onClick: (categoryId: number) => void
}

/**
 * スタンダードモードのタブ
 */
function Tab({ id, text, isActive, onClick }: TabProps) {

    const changePointer = useContext(ChangePointerContext)

    const isActiveClassName = isActive ? " is-current" : ""

    return (
        <button
            key={id}
            className={"tab" + isActiveClassName}
            onClick={() => onClick(id)}
            onMouseMove={() => changePointer(POINTER.ONBUTTON, "StandardTabs")}
            onMouseEnter={() => changePointer(POINTER.ONBUTTON, "StandardTabs")}
            onMouseLeave={() => changePointer(POINTER.IDLE, "StandardTabs")}
        >
            <span>{text}</span>
        </button>
    )
}


export default StandardTabs