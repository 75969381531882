import { Dispatch, SetStateAction } from 'react';
import * as THREE from 'three'

import * as CONF from 'src/configs'

const isShowLog = CONF.isDebug && false

/**
 * ロードの際、渡す必要があるのでStaticClassとする
 */
class VLLoadingManager {

    private static loadingManager = new THREE.LoadingManager()
    private static _textureLoader = new THREE.TextureLoader()
    static get textureLoader(): THREE.TextureLoader {
        console.assert(this._textureLoader, 'VLLoadingManager: textureLoaderが存在しません')
        return VLLoadingManager._textureLoader
    }

    //    static progress = 0
    static init(setLoadingProgress: Dispatch<SetStateAction<number>>) {

        VLLoadingManager.loadingManager.onStart = (url, itemsLoaded, itemsTotal) => {
            if (isShowLog) {
                console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
            }
        };

        VLLoadingManager.loadingManager.onLoad = () => {
            if (isShowLog) {
                console.log('Loading complete!');
            }
        };

        VLLoadingManager.loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
            if (isShowLog) {
                console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
            }

            if (itemsTotal > 0) {
                const progressPer = Math.floor(itemsLoaded / itemsTotal * 100)
                setLoadingProgress(progressPer)
            }
        };

        VLLoadingManager.loadingManager.onError = (url) => {
            if (isShowLog) {
                console.log('There was an error loading ' + url)
            }
        };

        VLLoadingManager._textureLoader.manager = VLLoadingManager.loadingManager
    }
}

export default VLLoadingManager