import { useRef } from "react"
import { CSSTransition } from "react-transition-group"

type Props = {
    isShown: boolean
}

const ContentBackLayer: React.FC<Props> = (props) => {

    const animeElement = useRef(null)

    return (
        <CSSTransition
            in={props.isShown}
            timeout={500}
            nodeRef={animeElement}
        >
            <div className="content-back-layer" ref={animeElement}></div>
        </CSSTransition>
    )
}

export default ContentBackLayer