import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { ChangePointerContext } from "src/AppContexts"
import { ContentDisplayedTypes, POINTER } from "src/interfaces"
import ContentParts from "./ContentParts"
import * as CONF from 'src/configs'
import * as UTILS from 'src/utils/Utils'
import Gtag from "src/utils/Gtag"

const Content = {

    TypeLink: ({ data }: { data: ContentDisplayedTypes }) => (
        <div className="content type-link">
            <ContentParts.Category
                classNameColor={data.classNameColor}
                text={data.categoryStr}
            />
            <ContentParts.TitleSummary
                classNameColor={data.classNameColor}
                title={data.title}
                summary={data.summary}
            />
            <figure className="image"><img src={data.imagePath} alt="" /></figure>
            <p className="description">{data.texts}</p>

            <ContentParts.Addition
                additions={data.additions}
            />

            <ContentParts.LinkButton
                title={data.title}
                linkPath={data.linkPath}
            >
                Webサイト
            </ContentParts.LinkButton>
        </div>
    ),



    TypeVideo: ({ data }: { data: ContentDisplayedTypes }) => {
        const changePointer = useContext(ChangePointerContext)

        useEffect(() => {
            // Youtube iFrame APIが読み込まれていることを確認
            if (window.YT) {

                const onPlayerStateChange = (event: YT.OnStateChangeEvent) => {
                    if (event.data === YT.PlayerState.PLAYING) {
                        // 再生時にデータを取る
                        const seektime = Math.floor(event.target.getCurrentTime())
                        Gtag.playVideo(data.title, seektime)
                    } else if (event.data === YT.PlayerState.PAUSED || event.data === YT.PlayerState.ENDED) {
                        // 停止、終了時にデータを取る
                        const seektime = Math.floor(event.target.getCurrentTime())
                        Gtag.stopVideo(data.title, seektime)
                    }
                }

                new YT.Player('player', {
                    events: {
                        'onStateChange': onPlayerStateChange
                    }
                })
            }
        }, [data])


        return (
            <div className="content type-video">
                <div className="video-block">
                    <iframe
                        id="player"
                        width="480"
                        height="270"
                        src={data.videoSrc + `?enablejsapi=1&origin=${CONF.url}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        onMouseMove={() => changePointer(POINTER.HIDDEN, "Content")}
                        onMouseEnter={() => changePointer(POINTER.HIDDEN, "Content")}
                        onMouseLeave={() => changePointer(POINTER.IDLE, "Content")}
                    />
                </div>

                <div className="info-block">
                    <ContentParts.Category
                        classNameColor={data.classNameColor}
                        text={data.categoryStr}
                    />
                    <ContentParts.TitleSummary
                        classNameColor={data.classNameColor}
                        title={data.title}
                        summary={data.summary}
                    />

                    <p className="description">{data.texts}</p>

                    <ContentParts.LinkButton
                        title={data.title}
                        linkPath={data.linkPath}
                    >
                        動画サイトへ
                    </ContentParts.LinkButton>
                </div>
            </div>
        )
    },



    Type3d: ({ data, setIsShowmMatterPort }: { data: ContentDisplayedTypes, setIsShowmMatterPort: Dispatch<SetStateAction<boolean>> }) => {
        const changePointer = useContext(ChangePointerContext)

        const onClick = () => {
            setIsShowmMatterPort(true)
            Gtag.openMatterport(data.title)
        }

        return (
            <div className="content type-3d">
                <ContentParts.Category
                    classNameColor={data.classNameColor}
                    text={data.categoryStr}
                />
                <ContentParts.TitleSummary
                    classNameColor={data.classNameColor}
                    title={data.title}
                    summary={data.summary}
                />
                <figure className="image">
                    <img src={data.imagePath} alt="" />
                </figure>
                <p className="description">{data.texts}</p>
                <div className="link">
                    {
                        // スマホまたはIEの時は、MatterPortのリンクへ飛ばす
                        (UTILS.isIE || UTILS.isSmartPhone()) ?
                            <ContentParts.LinkButtonMatterPort
                                title={data.title}
                                linkPath={data.linkPath}
                            >
                                3DでTheLab.を体験
                            </ContentParts.LinkButtonMatterPort>
                            :
                            <button
                                className="link__button"
                                onClick={() => onClick()}
                                onMouseMove={() => changePointer(POINTER.ONBUTTON, "Content")}
                                onMouseEnter={() => changePointer(POINTER.ONBUTTON, "Content")}
                                onMouseLeave={() => changePointer(POINTER.IDLE, "Content")}
                            >
                                3DでTheLab.を体験
                            </button>
                    }
                </div>
            </div>
        )
    }
}

export default Content