import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import StandardTabs from './StandardTabs'

import { CSSTransition } from 'react-transition-group'
import StandardList from './StandardList'
import Contents from 'src/models/Contents'
import { ContentStateTypes, ContentType } from 'src/interfaces'

type Props = {
    mode: number
    setContentState: Dispatch<SetStateAction<ContentStateTypes>>
}

/**
 * スタンダードモードの表示データとレイアウト
 */
const StandardLayout: React.FC<Props> = ({
    mode,
    setContentState,
}) => {

    const [currentCategoryId, setCurrentCategoryId] = useState(1)
    const [currentContents, setCurrentContents] = useState<ContentType[]>([])

    const animeElement = useRef(null)

    const isShown = mode === 1


    // 現在のタブが変更されたら、それに応じてデータをロードする
    useEffect(() => {
        const promise = Contents.init()

        promise.then(() => {
            const tempContents = Contents.getContentsByCategoryId(currentCategoryId)
            setCurrentContents(tempContents)
        })

    }, [currentCategoryId])


    // コンテンツ表示用の関数
    const showContentFromStandard = useCallback((contentId: number) => {
        setContentState({
            contentId: contentId,
            isShown: true,
            delay: 0
        })
    }, [setContentState])


    return (
        <CSSTransition
            in={isShown}
            timeout={500}
            mountOnEnter={true}
            unmountOnExit={true}
            nodeRef={animeElement}
        >
            <div className="standard-layout" ref={animeElement}>
                <div className="standard-layout__inner">

                    <StandardTabs
                        currentCategoryId={currentCategoryId}
                        setCurrentCategoryId={setCurrentCategoryId}
                    />

                    <StandardList
                        currentContents={currentContents}
                        showContent={showContentFromStandard}
                    />

                </div>
            </div>

        </CSSTransition>
    )

}

export default StandardLayout