import labLogo from 'src/assets/images/thelab-logo.svg'
import Gtag from 'src/utils/Gtag'

const SiteLogo = () => {
    return (
        <h1 className="site-logo">
            <img
                width='300'
                height='150'
                src={labLogo}
                alt="TheLab. みんなで世界一研究所"
                onClick={() => Gtag.clickLogo()}
            />
        </h1>
    )
}

export default SiteLogo