import { useEffect, useState } from 'react'
import ThreeCanvas from 'src/components/ThreeCanvas'

import ModeSwitcher from './components/ModeSwitcher'
import ContentFrame from './components/ContentFrame/ContentFrame'
import StandardLayout from './components/StandardLayout/StandardLayout'
import SiteLogo from './components/SiteLogo'
import HeaderButton from './components/HeaderButton'
import VirtualLab from './VirtualLab/VirtualLab'
import LoadingLayer from './components/LoadingLayer'
import PointerStalker from './components/PointerStalker'
import { IsOpeningEndProvider, LoadingProgressProvider, PointerStatusProvider } from './AppContexts'
import { ContentStateTypes } from './interfaces'
import * as UTILS from './utils/Utils'
import Gtag from './utils/Gtag'


/**
 * アプリケーション全体で使われるデータと構造を扱う
 */
function App() {

    // Current Mode
    const [mode, setMode] = useState(3)
    const [prevMode, setPrevMode] = useState(3)

    // Current Shown Content
    const [contentState, setContentState] = useState<ContentStateTypes>({
        contentId: -1,
        isShown: false,
        delay: 0
    })

    // virtualLab Three js Class
    const [virtualLab, setVirtualLab] = useState<VirtualLab | null>(null)

    useEffect(() => {
        Gtag.onChangeMode(mode)
    })


    const classNameDevice = UTILS.isSmartPhone() ? "sp" : "pc"

    return (
        <div className={`app ${classNameDevice}`}>

            <SiteLogo />

            <div className="copyright">
                <p className="copyright-text">Copyright © KNOWLEDGE CAPITAL All Rights Reserved.</p>
            </div>

            <PointerStatusProvider>
                <IsOpeningEndProvider>
                    <LoadingProgressProvider>
                        <LoadingLayer
                            virtualLab={virtualLab}
                        />

                        <ThreeCanvas
                            setContentState={setContentState}
                            virtualLab={virtualLab}
                            setVirtualLab={setVirtualLab}
                        />
                    </LoadingProgressProvider>

                    <HeaderButton
                        mode={mode}
                        setMode={setMode}
                        prevMode={prevMode}
                        setPrevMode={setPrevMode}
                        virtualLab={virtualLab}
                    />

                    <ModeSwitcher
                        mode={mode}
                        setMode={setMode}
                        setPrevMode={setPrevMode}
                        virtualLab={virtualLab}
                    />

                    <PointerStalker />

                </IsOpeningEndProvider>

                <ContentFrame
                    mode={mode}
                    contentState={contentState}
                    setContentState={setContentState}
                    virtualLab={virtualLab}
                />

                <StandardLayout
                    mode={mode}
                    setContentState={setContentState}
                />

            </PointerStatusProvider>

        </div>
    );
}

export default App;
