import { Dispatch, SetStateAction, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import { ContentDisplayedTypes } from "src/interfaces"
import Content from "./Content"
import ContentParts from "./ContentParts"

type Props = {
    isShown: boolean
    hideContent: () => void
    contentData: ContentDisplayedTypes
    setIsShownMatterPort: Dispatch<SetStateAction<boolean>>
}

/**
 * コンテンツの内容によって、レイアウトを整える
 */
const ContentLayer: React.FC<Props> = (props) => {

    const contentData = props.contentData

    const animeElement = useRef(null)

    const classNameFrameType = " frame-type-" + contentData.frameType

    const ContentLayout = () => {
        switch (contentData.frameType) {
            case 1:
                return (
                    <Content.TypeLink
                        data={props.contentData}
                    />
                )
            case 2:
                return (
                    <Content.TypeVideo
                        data={props.contentData}
                    />
                )
            case 3:
                return (
                    <Content.Type3d
                        data={props.contentData}
                        setIsShowmMatterPort={props.setIsShownMatterPort}
                    />
                )
        }

        return null
    }


    return (
        <CSSTransition
            in={props.isShown}
            timeout={600}
            nodeRef={animeElement}
        >
            <section className='content-layer'  >
                <div className={'content-layer__inner' + classNameFrameType} ref={animeElement}>

                    <ContentParts.CloseButton
                        isWhite={false}
                        onClick={props.hideContent}
                    />
                    <div className="content-layout">
                        <ContentLayout />
                    </div>

                </div>
            </section>
        </CSSTransition>
    )
}

export default ContentLayer