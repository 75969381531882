import * as THREE from 'three'

import * as UTILS from 'src/utils/Utils'

const groundSize = 50
const divisions = 100
const tileSize = groundSize / divisions
const tileActualSize = tileSize - 0.08
const tileHalfSize = tileSize / 2

const gridStartHue = 0.3
const gridGainHue = 0.025
const gridSaturation = 0.8
const gridBrightness = 0.85

const tileAreaSize = 20
const tileAreaHalfSize = tileAreaSize / 2
const tileAreaLength = tileAreaSize / tileSize
const numOfTiles = 150

const tileSaturation = 1
const tileBrightness = 0.95

const tileDurationOff = 4
const tileDurationOn = 2
const tileDurationFade = 0.2

const tileTimeOffEnd = tileDurationOff
const tileTimeFadeInEnd = tileDurationOff + tileDurationFade
const tileTimeOnEnd = tileTimeFadeInEnd + tileDurationOn
const tileTimeFadeOutEnd = tileTimeOnEnd + tileDurationFade

const tileOpacityGain = 1 / tileDurationFade

/**
 * 地面関係を構築するクラス
 */
class VLGround {

    /**
     * コンストラクタ。
     */
    constructor(scene: THREE.Scene) {
        const groundLines = new GroundGrid()
        const groundLineSegments = groundLines.groundLineSegments
        scene.add(groundLineSegments)

        // IEの時は光タイルを表示しない
        if (!UTILS.isIE) {
            const groundTiles = new GroundTiles()
            const groundTileMeshs = groundTiles.tilesGroup
            scene.add(groundTileMeshs)
        }
    }
}



/**
 * 地面のグリッドのクラス
 */
class GroundGrid {

    private _groundLineSegments: THREE.LineSegments
    get groundLineSegments() { return this._groundLineSegments }



    constructor() {

        const vertices: number[] = []
        const colors: number[] = []

        const step = groundSize / divisions;
        const halfSize = groundSize / 2;

        const color = new THREE.Color();
        let hue = gridStartHue

        for (let i = 0, j = 0, k = - halfSize; i <= divisions; i++, k += step) {

            vertices.push(- halfSize, 0, k, halfSize, 0, k)
            vertices.push(k, 0, - halfSize, k, 0, halfSize)

            hue += gridGainHue
            if (hue > 1) hue -= 1
            color.setHSL(hue, gridSaturation, gridBrightness)

            color.toArray(colors, j); j += 3;
            color.toArray(colors, j); j += 3;
            color.toArray(colors, j); j += 3;
            color.toArray(colors, j); j += 3;
        }

        const geometry = new THREE.BufferGeometry();

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

        const material = new THREE.LineBasicMaterial({
            vertexColors: true,
        });

        this._groundLineSegments = new THREE.LineSegments(geometry, material)
    }
}



/**
 * タイルを構築するクラス
 */
class GroundTiles {

    private _tilesGroup: THREE.Group = new THREE.Group()
    get tilesGroup() { return this._tilesGroup }

    private tiles: Tile[] = []

    private clock: THREE.Clock = new THREE.Clock()
    private delta: number = 0

    constructor() {

        const color = new THREE.Color()

        let hue = 0
        const gainHue = 1 / numOfTiles * 2

        for (let i = 0; i < numOfTiles; i++) {
            hue += gainHue
            if (hue > 1) hue -= 1

            color.setHSL(hue, tileSaturation, tileBrightness)

            const tile = new Tile(color)

            this.tilesGroup.add(tile.tileMesh)
            this.tiles.push(tile)
        }

        this.update()

    }

    update = () => {
        requestAnimationFrame(this.update)

        this.delta = this.clock.getDelta()

        this.tiles.forEach((tile: Tile) => {
            tile.update(this.delta)
        })
    }
}



/**
 * 各タイルの処理
 */
class Tile {

    private static readonly planeGeometry = new THREE.PlaneGeometry(tileActualSize, tileActualSize)

    private _tileMesh: THREE.Mesh
    get tileMesh(): THREE.Mesh { return this._tileMesh }

    private material: THREE.MeshBasicMaterial
    private opacity: number

    private time: number


    constructor(color: THREE.Color) {

        this.opacity = Math.random()

        this.material = new THREE.MeshBasicMaterial({
            color: color,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: this.opacity,
            alphaTest: 0.1
        })
        this.material.alphaTest = 0.1

        this._tileMesh = new THREE.Mesh(Tile.planeGeometry, this.material)
        this.tileMesh.rotation.x = 0.5 * Math.PI
        this.computeTilePosition()

        this.time = Math.random() * tileTimeFadeOutEnd

    }



    private computeTilePosition() {

        const x = Math.floor(Math.random() * tileAreaLength) * tileSize - tileAreaHalfSize + tileHalfSize
        const z = Math.floor(Math.random() * tileAreaLength) * tileSize - tileAreaHalfSize + tileHalfSize

        this.tileMesh.position.set(x, 0, z)

    }



    update(delta: number) {

        this.time += delta

        while (this.time > tileTimeFadeOutEnd) {
            this.time -= tileTimeFadeOutEnd
            this.computeTilePosition()
        }

        if (this.time < tileTimeOffEnd) {
            if (this.opacity !== 0) {
                this.opacity = 0
                if (this.opacity > 1) this.opacity = 1
                this.material.opacity = this.opacity
            }
        } else if (this.time < tileTimeFadeInEnd) {
            this.opacity += tileOpacityGain * delta
            this.material.opacity = this.opacity
        } else if (this.time < tileTimeOnEnd) {
            if (this.opacity !== 1) {
                this.opacity = 1
                this.material.opacity = this.opacity
            }
        } else {
            this.opacity -= tileOpacityGain * delta
            if (this.opacity < 0) this.opacity = 0
            this.material.opacity = this.opacity
        }

    }
}




export default VLGround