import * as THREE from 'three'
import * as TWEEN from '@tweenjs/tween.js'

import VLLoadingManager from 'src/VirtualLab/VLLoadingManager'

import * as UTILS from 'src/utils/Utils'

/* Configs ----------------------------------- */
const cardSize = 1
const cardEffectSize = 1.2
const cardScaleHover = 1.05

// アイドリング状態の上下の振れ幅(実際はこの二倍の振れ幅となる)
const amplitude = 0.02
// 揺れる速度（ラジアン、6.28以下で）
const swingSpeed = 0.02
// テクスチャに適応するマテリアルカラー
const colorEffectDefault = 0xbbffbb
const colorEffectHover = 0xaaffff
const colorEffectSelecting = 0xffbbbb
const colorEffectSelected = 0xffbbff


/**
 * コンテンツを表示するサムネカードを生成するクラス。
 */
class VLCard {

    private _contentId: number
    get contentId(): number { return this._contentId }
    private _cardObj: THREE.Group
    get cardObj(): THREE.Group { return this._cardObj }

    private effectMaterial: THREE.MeshBasicMaterial
    private effectMesh: THREE.Mesh

    private cardTween = new TWEEN.Group()

    private isSwing: boolean
    private centerPosY: number
    private tempSwingRadian: number

    private isSelected: boolean = false

    /**
     * コンストラクタ
     */
    constructor(contentId: number, imagePath: string) {

        this._contentId = contentId

        this._cardObj = new THREE.Group()
        this.cardObj.name = "cardID: " + this.contentId

        // setting swing
        this.isSwing = false
        this.centerPosY = this.cardObj.position.y
        this.tempSwingRadian = Math.random() * UTILS.PI2

        // Generate Card
        const geometry = new THREE.PlaneGeometry(cardSize, cardSize)

        const loader = VLLoadingManager.textureLoader
        const texture = loader.load(imagePath)
        const material = new THREE.MeshBasicMaterial({
            color: 0xFFFFFF,
            side: THREE.FrontSide,
            map: texture
        })

        const cardFrontMesh = new THREE.Mesh(geometry, material);
        const cardBackMesh = new THREE.Mesh(geometry, material);
        cardFrontMesh.position.z += 0.01
        cardBackMesh.position.z -= 0.01
        cardBackMesh.rotation.y = Math.PI

        this.cardObj.add(cardFrontMesh)
        this.cardObj.add(cardBackMesh)

        // Generate Effect
        const effectGeometry = new THREE.PlaneGeometry(cardEffectSize, cardEffectSize)
        const effectTexture = loader.load('./images/three/card-effect.png')

        this.effectMaterial = new THREE.MeshBasicMaterial({
            color: colorEffectDefault,
            map: effectTexture,
            side: THREE.DoubleSide,
            transparent: true,
            alphaTest: 0.1
        })

        this.effectMesh = new THREE.Mesh(effectGeometry, this.effectMaterial)
        this.effectMesh.layers.enable(1)
        this.cardObj.add(this.effectMesh)

        this.update()
    }


    /**
     * Update
     */
    private update = () => {
        requestAnimationFrame(this.update)

        // Swing
        if (this.isSwing) {
            this.cardObj.position.y = this.centerPosY + Math.sin(this.tempSwingRadian) * amplitude
            this.tempSwingRadian += swingSpeed
            if (this.tempSwingRadian > Math.PI) {
                this.tempSwingRadian -= UTILS.PI2
            }
        }

        this.cardTween.update()
    }


    /**
     * カードを移動します
     */
    moveTo(
        targetPos: THREE.Vector3,
        targetRot: THREE.Vector3,
        duration: number,
        isSwingOnComplete: boolean
    ) {

        this.isSwing = false
        this.cardTween.removeAll()

        this.centerPosY = targetPos.y
        if (isSwingOnComplete) {
            targetPos.y += Math.sin(this.tempSwingRadian) * amplitude
        }

        new TWEEN.Tween(this.cardObj.rotation, this.cardTween)
            .to({ x: targetRot.x, y: targetRot.y, z: targetRot.z }, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();

        new TWEEN.Tween(this.cardObj.position, this.cardTween)
            .to({ x: targetPos.x, y: targetPos.y, z: targetPos.z }, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onComplete(() => this.isSwing = isSwingOnComplete)
            .start();
    }


    getFrontPosition(mode: number): THREE.Vector3 {
        let position = new THREE.Vector3()
        position.copy(this.cardObj.position)
        const worldPos = this.cardObj.getWorldPosition(new THREE.Vector3())

        switch (mode) {
            case 3:
                let frontVec = new THREE.Vector3(worldPos.x, worldPos.y, worldPos.z)
                frontVec = frontVec.normalize()
                position.x = worldPos.x + frontVec.x * 3
                position.y = worldPos.y
                position.z = worldPos.z + frontVec.z * 3
                break;
            case 2:
                position.y = position.y + 3
                break
        }
        return position
    }


    onPointerEnter() {
        this.effectMaterial.color.setHex(colorEffectHover)
        this.effectMesh.scale.x = cardScaleHover
        this.effectMesh.scale.y = cardScaleHover
    }


    onPointerLeave() {
        if (this.isSelected) {
            this.effectMaterial.color.setHex(colorEffectSelected)
        } else {
            this.effectMaterial.color.setHex(colorEffectDefault)
        }
        this.effectMesh.scale.x = 1
        this.effectMesh.scale.y = 1
    }


    onPointerActive() {
        this.effectMaterial.color.setHex(colorEffectSelecting)
    }


    onSelect() {
        this.isSelected = true
    }

}


export default VLCard