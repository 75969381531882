import * as THREE from 'three'

import * as CONF from 'src/configs'
import VLControls from './VLControls'
import * as TWEEN from '@tweenjs/tween.js'
import { isSmartPhone } from 'src/utils/Utils'

const isDebug = CONF.isDebug && true

const pcPov = 50
const spPov = 60

// 3dモードでコンテンツを閉じた時、どれくらいカメラを引くか
const hide3DDistance = 3

const hide2Drate = 0.5
const hide2DDistanceY = CONF.cardsFormationTableY + CONF.cameraPos2DModeDistanceY * hide2Drate
const hide2DOffsetZ = CONF.cameraPos2DModeOffsetZ * hide2Drate

/**
 * カメラ
 */
class VLCamera {

    private _vlControls: VLControls
    get vlControls(): VLControls { return this._vlControls }
    private _camera: THREE.PerspectiveCamera
    get camera(): THREE.PerspectiveCamera { return this._camera }

    private rotateTween = new TWEEN.Group()
    private posTween = new TWEEN.Group()
    private zoomTween = new TWEEN.Group()

    private isRequestProjectionUpdate: boolean = false

    private tempPosition = new THREE.Vector3()
    private tempLookPos = new THREE.Vector3()


    /**
     * コンストラクタ
     */
    constructor(mode: number, canvasElement: HTMLCanvasElement) {

        let pov = pcPov
        if (isSmartPhone()) pov = spPov
        this._camera = new THREE.PerspectiveCamera(
            pov,
            window.innerWidth / window.innerHeight,
            CONF.cameraNear,
            CONF.cameraFar
        );

        this.camera.position.copy(CONF.cameraPosInit)
        this.camera.lookAt(CONF.cameraLookInit)
        this.camera.updateProjectionMatrix()

        // setup Controls
        this._vlControls = new VLControls(mode, this.camera, canvasElement);
        this.vlControls.updateControls(CONF.cameraPosInit, CONF.cameraLookInit)

        // resize
        window.addEventListener('resize', this.onResize);
    }



    onResize = () => {
        if (CONF.isDebug) console.log("リサイズの処理をします");

        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
    }



    update = () => {

        requestAnimationFrame(this.update)

        this.rotateTween.update()
        this.posTween.update()
        this.zoomTween.update()

        if (this.isRequestProjectionUpdate) {
            this.isRequestProjectionUpdate = false
            this.camera.updateProjectionMatrix()
        }

    }



    /**
     * スタート時に呼ばれる。スタートアニメーション
     */
    onStart() {
        // start anime update
        this.update()

        // phase 1
        const targetCamera1 = this.generateTargetCamera(CONF.cameraPosStart1, CONF.cameraLookStart1)
        const tweenMove1 = this.moveTo(
            targetCamera1.position,
            CONF.cameraDurationMoveStart1,
            CONF.cameraDelayMoveStart1,
            false
        )
        const tweenRotate1 = this.rotateTo(
            this.camera.quaternion,
            targetCamera1.quaternion,
            CONF.cameraDurationLookStart1,
            CONF.cameraDelayLookStart1,
            false
        )

        // phase 2
        const targetCamera2 = this.generateTargetCamera(CONF.cameraPosStart2, CONF.cameraLookStart2)
        const tweenMove2 = this.moveTo(
            targetCamera2.position,
            CONF.cameraDurationMoveStart2,
            CONF.cameraDelayMoveStart2,
            false
        )
        const tweenRotate2 = this.rotateTo(
            targetCamera1.quaternion,
            targetCamera2.quaternion,
            CONF.cameraDurationLookStart2,
            CONF.cameraDelayLookStart2,
            false
        )

        // phase 3 to 3D Mode
        const targetCamera3 = this.generateTargetCamera(CONF.cameraPos3DMode, CONF.cameraLook3DMode)
        const tweenMove3 = this.moveTo(
            targetCamera3.position,
            CONF.cameraDurationMoveStart3,
            CONF.cameraDelayMoveStart3,
            false
        )
        const tweenRotate3 = this.rotateTo(
            targetCamera2.quaternion,
            targetCamera3.quaternion,
            CONF.cameraDurationLookStart3,
            CONF.cameraDelayLookStart3,
            false
        )

        // chain
        tweenMove1.chain(tweenMove2)
        tweenRotate1.chain(tweenRotate2)
        tweenMove2.chain(tweenMove3)
        tweenRotate2.chain(tweenRotate3)

        // start
        tweenMove1.start()
        tweenRotate1.start()


        this.vlControls.updateControls(CONF.cameraPos3DMode, CONF.cameraLook3DMode, CONF.controlsDelayEnableStart)
        this.vlControls.enableControl(CONF.controlsDelayEnableStart)
    }


    private rotateTo(
        fromQua: THREE.Quaternion,
        toQua: THREE.Quaternion,
        duration: number,
        delay: number = 0,
        isStart: boolean = true
    ) {
        this.rotateTween.removeAll()

        const tempFromQua = new THREE.Quaternion()
        tempFromQua.copy(fromQua)
        const tempToQua = new THREE.Quaternion()
        tempToQua.copy(toQua)

        let progress = { t: 0 }
        const tween = new TWEEN.Tween(progress, this.rotateTween)
            .to({ t: 1 }, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate((tween) => {
                this.camera.quaternion.slerpQuaternions(tempFromQua, tempToQua, tween.t)
                this.isRequestProjectionUpdate = true
            })
            .delay(delay)

        if (isStart) tween.start()
        return tween
    }



    private moveTo(
        position: THREE.Vector3,
        duration: number,
        delay: number = 0,
        isStart: boolean = true
    ) {
        this.posTween.removeAll()

        const tween = new TWEEN.Tween(this.camera.position, this.posTween)
            .to({ x: position.x, y: position.y, z: position.z }, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate(() => this.isRequestProjectionUpdate = true)
            .delay(delay)

        if (isDebug) tween.onStart(() => console.log({ messsage: `VLCamera: カメラ移動tweenを開始`, to: position }))

        if (isStart) tween.start()
        return tween
    }



    private zoomTo(zoom: number, duration: number, delay: number = 0, isStart: boolean = true) {
        this.zoomTween.removeAll()

        const tween = new TWEEN.Tween({ 'zoom': this.camera.zoom }, this.zoomTween)
            .to({ 'zoom': zoom }, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate((tween) => {
                this.camera.zoom = tween.zoom
                this.isRequestProjectionUpdate = true
            })
            .delay(delay)

        if (isStart) tween.start()
        return tween
    }


    private generateTargetCamera(
        position: THREE.Vector3, look: THREE.Vector3): THREE.Camera {

        const targetCamera = new THREE.Camera()

        targetCamera.position.copy(position)
        targetCamera.lookAt(look)

        return targetCamera
    }


    /**
     * モードを切り替えます
     */
    onChangeMode(mode: number) {
        if (!(mode === 3 || mode === 2)) {
            if (isDebug) console.error(`渡されたmodeが不正です mode: ${mode}`)
            return
        }

        if (isDebug) console.log(`カメラをmode: ${mode}に切り替えます`)

        this.vlControls.onChangeMode(mode)

        let targetCamera: THREE.Camera
        let tempZoom = 1
        const duration = CONF.durationChangeMode

        switch (mode) {
            case 3:
                targetCamera = this.generateTargetCamera(CONF.cameraPos3DMode, CONF.cameraLook3DMode)
                tempZoom = CONF.cameraZoom3DMode

                this.vlControls.updateControls(CONF.cameraPos3DMode, CONF.cameraLook3DMode, duration)
                break
            case 2:
                targetCamera = this.generateTargetCamera(CONF.cameraPos2DMode, CONF.cameraLook2DMode)
                tempZoom = CONF.cameraZoom2DMode

                this.vlControls.updateControls(CONF.cameraPos2DMode, CONF.cameraLook2DMode, duration)
                break
        }

        this.rotateTo(this.camera.quaternion, targetCamera.quaternion, duration)
        this.moveTo(targetCamera.position, duration)
        this.zoomTo(tempZoom, duration)
    }


    showContent(cameraPos: THREE.Vector3, lookPos: THREE.Vector3) {
        this.vlControls.disableAllControls()

        const targetCamera = this.generateTargetCamera(cameraPos, lookPos)
        const duration = CONF.durationShowContent

        this.rotateTo(this.camera.quaternion, targetCamera.quaternion, duration)
        this.moveTo(targetCamera.position, duration)
        this.zoomTo(CONF.cameraZoomShowContent, duration)
    }


    /**
     * コンテンツを非表示にする時に呼ばれる処理。
     */
    onHideContent(mode: number) {
        this.vlControls.disableAllControls()

        let tempZoom = 1
        switch (mode) {
            case 3:
                this.tempPosition.copy(this.camera.position)
                this.tempPosition.normalize()

                this.tempPosition.x = this.camera.position.x + this.tempPosition.x * hide3DDistance
                this.tempPosition.y = this.camera.position.y
                this.tempPosition.z = this.camera.position.z + this.tempPosition.z * hide3DDistance

                this.tempLookPos.x = CONF.cameraLook3DMode.x
                this.tempLookPos.y = this.camera.position.y
                this.tempLookPos.z = CONF.cameraLook3DMode.z

                tempZoom = CONF.cameraZoom3DMode
                break
            case 2:
                this.tempPosition.x = this.camera.position.x
                this.tempPosition.y = hide2DDistanceY
                this.tempPosition.z = this.camera.position.z + hide2DOffsetZ

                this.tempLookPos.x = this.camera.position.x
                this.tempLookPos.y = CONF.cameraLook2DMode.y
                this.tempLookPos.z = this.camera.position.z

                tempZoom = CONF.cameraZoom2DMode
                break
            default:
                if (isDebug) console.error(`渡されたmode値が不正です`)
                break
        }

        const targetCamera = this.generateTargetCamera(this.tempPosition, this.tempLookPos)
        const duration = CONF.durationHideContent

        this.moveTo(targetCamera.position, duration)
        this.rotateTo(this.camera.quaternion, targetCamera.quaternion, duration)
        this.zoomTo(tempZoom, duration)

        this.vlControls.updateControls(targetCamera.position, this.tempLookPos, duration)
        this.vlControls.enableControl(duration + 10)
    }


    onRecommend(cameraPos: THREE.Vector3, lookPos: THREE.Vector3) {
        this.vlControls.disableAllControls()

        const targetCamera = this.generateTargetCamera(cameraPos, lookPos)

        this.rotateTo(this.camera.quaternion, targetCamera.quaternion, CONF.durationChangeMode)
        this.moveTo(targetCamera.position, CONF.durationChangeMode)
    }


    onStopRecommend(mode: number) {

        this.posTween.removeAll()
        this.rotateTween.removeAll()

        const cameraPos = this.camera.position
        const lookPos = new THREE.Vector3()

        switch (mode) {
            case 3:
                lookPos.x = CONF.cameraLook3DMode.x
                lookPos.y = this.camera.position.y
                lookPos.z = CONF.cameraLook3DMode.z
                break
            case 2:
                lookPos.x = this.camera.position.x
                lookPos.y = CONF.cameraLook2DMode.y
                lookPos.z = this.camera.position.z
        }

        this.vlControls.updateControls(cameraPos, lookPos)
        this.vlControls.enableControl()
    }

}

export default VLCamera