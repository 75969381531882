import * as THREE from 'three'
import * as CONF from 'src/configs'
import * as UTILS from 'src/utils/Utils'

// ランダムフォーメーションで、散らばらせる範囲の最大値
const randomFormationSize = 20

// 1周あたりで増える半径の大きさ
const helixGainRadius = 1 / UTILS.PI2
// 1周あたりで増える高さ
const helixGainY = 1.5 / UTILS.PI2
// 高さのオフセット
const helixOffsetY = 1
// カードとカードのの間隔
const helixSpace = 1.25
// 何周回った時点からスタートするか
const helixOffsetRadian = 3 * Math.PI


/**
 * ランダムフォーメーションを作成
 */
export function generateRandomFormations(numOfCards: number): THREE.Object3D[] {

    const formations: THREE.Object3D[] = []

    for (let i = 0; i < numOfCards; i++) {
        const object = new THREE.Object3D();

        object.position.x = randomFormationSize * (Math.random() - 0.5)
        object.position.y = randomFormationSize * (Math.random() - 0.5)
        object.position.z = randomFormationSize * (Math.random() - 0.5)

        object.rotation.x = Math.PI * Math.random()
        object.rotation.y = Math.PI * Math.random()
        object.rotation.z = Math.PI * Math.random()

        formations.push(object)
    }

    return formations
}



/**
 * 球体フォーメーションを作成
 */
export function generateSphereFormations(numOfCards: number): THREE.Object3D[] {

    const formations: THREE.Object3D[] = []

    const radius = numOfCards * 0.07
    const vector = new THREE.Vector3();

    for (let i = 1, l = numOfCards + 1; i < l; i++) {
        const phi = Math.acos(-1 + (2 * i) / l)
        const theta = Math.sqrt(l * Math.PI) * phi

        const object = new THREE.Object3D();

        object.position.setFromSphericalCoords(radius, phi, theta);
        vector.copy(object.position).multiplyScalar(2);
        object.lookAt(vector);

        formations.push(object);
    }
    return formations

}



/**
 * HelixFormationを生成する
 */
export function generateHelixFormations(numOfCards: number,): THREE.Object3D[] {

    const formations: THREE.Object3D[] = []
    let radian = helixOffsetRadian

    for (let i = 0; i < numOfCards; i++) {

        const radius = helixGainRadius * radian

        const addRadian = helixSpace / radius
        radian += addRadian

        const x = helixGainRadius * radian * Math.cos(radian)
        const y = helixGainY * (radian - helixOffsetRadian) + helixOffsetY
        const z = - helixGainRadius * radian * Math.sin(radian)

        const object = new THREE.Object3D()
        object.position.set(x, y, z)

        const vector = new THREE.Vector3()
        vector.x = object.position.x * 2
        vector.y = object.position.y
        vector.z = object.position.z * 2
        object.lookAt(vector)

        formations.push(object)
    }

    return formations
}



/**
 * TableFormationsを生成する
 */
export function generateTableFormations(numOfCards: number) {

    const formations: THREE.Object3D[] = []
    const numOfSide = Math.ceil(Math.sqrt(numOfCards))

    const centerPos = new THREE.Vector3(0, CONF.cardsFormationTableY, 0)
    const space = 0.5 + 1

    for (let i = 0; i < numOfCards; i++) {
        const object = new THREE.Object3D();

        object.position.x = i % numOfSide * space - space * numOfSide / 2 + space / 2
        object.position.y = centerPos.y
        object.position.z = Math.floor(i / numOfSide) * space - space * numOfSide / 2 + space / 2

        object.lookAt(new THREE.Vector3(object.position.x, 1, object.position.z))
        object.rotation.z = Math.PI

        formations.push(object)
    }

    return formations
}


