import React, { Dispatch, SetStateAction, useContext, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import { ChangePointerContext } from "src/AppContexts"
import { POINTER } from "src/interfaces"
import Gtag from "src/utils/Gtag"

type CloseButtonTypes = {
    isWhite: boolean
    onClick: () => void
}

type CategoryTypes = {
    classNameColor: string,
    text: string
}

type TitleSummaryTypes = {
    classNameColor: string,
    title: string,
    summary: string
}

type LinkButtonTypes = {
    title: string
    linkPath: string
    children: React.ReactText
}

type LinkButtonMatterPortTypes = {
    title: string
    linkPath: string
    children: React.ReactText
}

/**
 * コンテンツ表示に使われるパーツ
 */
const ContentParts = {


    CloseButton: (props: CloseButtonTypes) => {
        const changePointer = useContext(ChangePointerContext)
        const classNameWhite = props.isWhite ? " close-button--white" : ""

        const onClick = () => {
            changePointer(POINTER.IDLE, "ContentParts")
            props.onClick()
        }

        return (
            <button
                className={"content-parts close-button" + classNameWhite}
                onClick={() => onClick()}
                onMouseMove={() => changePointer(POINTER.ONCANCEL, "ContentParts")}
                onMouseEnter={() => changePointer(POINTER.ONCANCEL, "ContentParts")}
                onMouseLeave={() => changePointer(POINTER.IDLE, "ContentParts")}
            ></button>
        )
    },


    Category: (props: CategoryTypes) => (
        <p className="content-parts category">
            <span className={props.classNameColor}>{props.text}</span>
        </p>
    ),


    TitleSummary: ({ classNameColor, title, summary }: TitleSummaryTypes) => (
        <div className={"content-parts title-summary" + classNameColor}>
            <h3 className="title" >{title}</h3>
            {
                summary !== "" &&
                <p className="summary">{summary}</p>
            }
        </div>
    ),


    Addition: ({ additions }: { additions: JSX.Element[] }) => {
        if (additions.length > 0) {
            return <p className="content-parts addition">{additions}</p>
        }
        return null
    },


    Image: (imagePath: string) => (
        <figure className="content-parts image"><img src={imagePath} alt="" /></figure>
    ),


    LinkButton: (props: LinkButtonTypes) => {
        const changePointer = useContext(ChangePointerContext)

        const onClick = () => {
            Gtag.clickLink(props.title)
        }

        return (
            <div className="link">
                <a
                    className="link__button"
                    href={props.linkPath}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => onClick()}
                    onMouseEnter={() => changePointer(POINTER.ONANCHOR, "ContentParts")}
                    onMouseMove={() => changePointer(POINTER.ONANCHOR, "ContentParts")}
                    onMouseLeave={() => changePointer(POINTER.IDLE, "ContentParts")}
                >{props.children}</a>
            </div>
        )
    },


    LinkButtonMatterPort: (props: LinkButtonMatterPortTypes) => {
        const changePointer = useContext(ChangePointerContext)

        const onClick = () => {
            Gtag.openMatterport(props.title)
        }

        return (
            <div className="link">
                <a
                    className="link__button"
                    href={props.linkPath}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => onClick()}
                    onMouseEnter={() => changePointer(POINTER.ONANCHOR, "ContentParts")}
                    onMouseMove={() => changePointer(POINTER.ONANCHOR, "ContentParts")}
                    onMouseLeave={() => changePointer(POINTER.IDLE, "ContentParts")}
                >{props.children}</a>
            </div>
        )
    }
}



/* Matter Port View ----------------------------------- */
type MatterPortViewProps = {
    isShownMatterPort: boolean
    setIsShownMatterPort: Dispatch<SetStateAction<boolean>>
    linkURL: string
}

const MatterPortView: React.FC<MatterPortViewProps> = (props) => {

    const changePointer = useContext(ChangePointerContext)
    const animeElement = useRef(null)

    return (
        <CSSTransition
            in={props.isShownMatterPort}
            timeout={500}
            mountOnEnter={true}
            unmountOnExit={true}
            nodeRef={animeElement}
        >
            <div className="matter-port-view" ref={animeElement}>
                <ContentParts.CloseButton
                    isWhite={true}
                    onClick={() => { props.setIsShownMatterPort(false) }}
                />
                <iframe width='853' height='480' src={props.linkURL} title="Matterport TheLab" frameBorder="0" allowFullScreen
                    onMouseMove={() => changePointer(POINTER.HIDDEN, "ContentParts")}
                />
            </div>
        </CSSTransition>
    )
}

export default ContentParts
export { MatterPortView }