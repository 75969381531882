import * as CONF from 'src/configs'

const isDebug = CONF.isDebug && true
const isActive = !CONF.isDebug || true

const clickLimmiter = 1000

const CATEGORY = {
    OTHER: "other",
    SHOW_CONTENT: "show_content",
    CONTENT: "content",
    CHANGE_MODE: "change_mode",
    MODE_3D: "mode_3d",
    MODE_2D: "mode_2d",
    MODE_STANDARD: "mode_standard"
} as const


export const LABEL = {
    TO_3D: "to_3d",
    TO_2D: "to_2d",
    TO_STANDARD: "to_standard"
} as const


/**
 * Gtag実装のためのクラス
 * Gtagの設置と初期化は、"public/index.html" で行っているので注意
 */
class Gtag {

    private static loadFinishTime: number = 0
    private static openingEndTime: number = 0
    private static recommendTime: number = 0

    private static modeStr: string = ""

    private static isFirstShowContent = true
    private static isFirstClickChangeMode = true
    private static isFirstDragVirtual = true
    private static isFirstDragRightVirtual = true
    private static isFirstScrollVirtual = true

    private static isEnableKurokoClick = true
    private static isEnableComuClick = true
    private static isEnableLogoClick = true

    /**
     * ロード完了時の時間を取得
     */
    static onLoadFinised() {
        if (isDebug) console.log(`Gtag:ロード完了からの計測開始`);
        if (isActive) Gtag.loadFinishTime = window.performance.now()
    }


    /**
     * オープニング完了時、計測用の時間を測り始める
     */
    static onOpeningEnd() {
        if (isDebug) console.log(`Gtag:オープニング完了からの計測開始`);
        if (isActive) Gtag.openingEndTime = window.performance.now()
    }


    /**
     * モード変更時に呼ばれる
     */
    static onChangeMode(mode: number) {
        if (mode === 3) Gtag.modeStr = CATEGORY.MODE_3D
        if (mode === 2) Gtag.modeStr = CATEGORY.MODE_2D
        if (mode === 1) Gtag.modeStr = CATEGORY.MODE_STANDARD

        if (isDebug) console.log(`モードを変更します num:${mode} string:${Gtag.modeStr}`);
    }


    /**
     * オープニング開始の計測用
     */
    static clickStart() {
        const endTime = window.performance.now()
        const duration = Math.floor(endTime - Gtag.loadFinishTime)

        if (isDebug) console.log({ gtag: 'click_start', details: { event_category: CATEGORY.OTHER, value: duration } })
        if (isActive) window.gtag('event', 'click_start', { event_category: CATEGORY.OTHER, value: duration });
    }



    /**
     * オープニング完了から、初めてコンテンツがクリックされるまで
     */
    private static firstShowContent() {
        if (Gtag.isFirstShowContent) {
            Gtag.isFirstShowContent = false

            const endTime = window.performance.now()
            const duration = Math.floor(endTime - Gtag.openingEndTime)

            if (isDebug) console.log({ gtag: 'first_show_content', event_category: CATEGORY.OTHER, value: duration })
            if (isActive) window.gtag('event', 'first_show_content', { event_category: CATEGORY.OTHER, value: duration });
        }
    }


    /**
     * オープニング完了から、初めてモードを切り替えるまで
     */
    private static firstChangeMode(from: string) {
        if (Gtag.isFirstClickChangeMode) {
            Gtag.isFirstClickChangeMode = false

            const endTime = window.performance.now()
            const duration = Math.floor(endTime - Gtag.openingEndTime)

            let action = ""
            if (from === "button") action = "first_changemode_by_button"
            if (from === "header") action = "first_changemode_by_header"

            if (isDebug) console.log({ gtag: action, event_category: CATEGORY.OTHER, value: duration })
            if (isActive) window.gtag('event', action, { event_category: CATEGORY.OTHER, value: duration });
        }
    }


    /**
     * 3D,2D空間で右ドラッグ操作をしたか
     */
    static firstDragVirtual() {
        if (Gtag.isFirstDragVirtual) {
            Gtag.isFirstDragVirtual = false

            const endTime = window.performance.now()
            const duration = Math.floor(endTime - Gtag.openingEndTime)

            if (isDebug) console.log({ gtag: "first_drag_virtual", event_category: Gtag.modeStr, value: duration })
            if (isActive) window.gtag('event', "first_drag_virtual", { event_category: Gtag.modeStr, value: duration });
        }
    }


    /**
     * 3D,2D空間で右ドラッグ操作をしたか
     */
    static firstDragRightVirtual() {
        if (Gtag.isFirstDragRightVirtual) {
            Gtag.isFirstDragRightVirtual = false

            const endTime = window.performance.now()
            const duration = Math.floor(endTime - Gtag.openingEndTime)

            if (isDebug) console.log({ gtag: "first_drag_right_virtual", event_category: Gtag.modeStr, value: duration })
            if (isActive) window.gtag('event', "first_drag_right_virtual", { event_category: Gtag.modeStr, value: duration });
        }
    }


    /**
     * 3D,2D空間でスクロールしたか
     */
    static firstScrollVirtual() {
        if (Gtag.isFirstScrollVirtual) {
            Gtag.isFirstScrollVirtual = false

            const endTime = window.performance.now()
            const duration = Math.floor(endTime - Gtag.openingEndTime)

            if (isDebug) console.log({ gtag: "first_scroll_virtual", event_category: Gtag.modeStr, value: duration })
            if (isActive) window.gtag('event', "first_scroll_virtual", { event_category: Gtag.modeStr, value: duration });
        }
    }


    /**
     * 3D,2D空間でピンチ操作をしたか
     */
    static firstPinchVirtual() {
        if (Gtag.isFirstDragRightVirtual) {
            Gtag.isFirstDragRightVirtual = false

            const endTime = window.performance.now()
            const duration = Math.floor(endTime - Gtag.openingEndTime)

            if (isDebug) console.log({ gtag: "first_pinch_virtual", event_category: Gtag.modeStr, value: duration })
            if (isActive) window.gtag('event', "first_pinch_virtual", { event_category: Gtag.modeStr, value: duration });
        }
    }


    /**
     * 3D, 2D空間でレコメンドモード開始
     */
    static startRecommend() {
        if (isDebug) console.log({ gtag: 'start_recommend', event_category: Gtag.modeStr })
        if (isActive) {
            Gtag.recommendTime = window.performance.now()
            window.gtag('event', 'start_recommend', { event_category: Gtag.modeStr });
        }
    }


    /**
     * 3D, 2D空間でレコメンドモード終了
     */
    static stopRecommend() {
        if (isDebug) console.log({ gtag: 'stop_recommend', event_category: Gtag.modeStr })
        if (isActive) {
            const endTime = window.performance.now()
            const duration = Math.floor(endTime - Gtag.recommendTime)

            window.gtag('event', 'stop_recommend', { event_category: Gtag.modeStr, value: duration });
        }
    }


    /**
     * 黒子ちゃんをクリック
     */
    static clickKuroko() {
        if (this.isEnableKurokoClick) {
            this.isEnableKurokoClick = false

            if (isDebug) console.log({ gtag: 'click_kuroko', event_category: Gtag.modeStr })
            if (isActive) window.gtag('event', 'click_kuroko', { event_category: Gtag.modeStr });

            window.setTimeout(() => this.isEnableKurokoClick = true, clickLimmiter)
        }
    }


    /**
     * コミュニケーターさんをクリック
     */
    static clickComunicator() {
        if (this.isEnableComuClick) {
            this.isEnableComuClick = false

            if (isDebug) console.log({ gtag: 'click_comunicator', event_category: Gtag.modeStr })
            if (isActive) window.gtag('event', 'click_comunicator', { event_category: Gtag.modeStr });

            window.setTimeout(() => this.isEnableComuClick = true, clickLimmiter)
        }
    }


    /**
     * コンテンツを表示したときに3d
     */
    static showContent3d(contentName: string, id: number) {
        Gtag.firstShowContent()
        
        this.sendPageView(contentName, id);

        if (isDebug) console.log({ gtag: 'show_content_3d', event_category: CATEGORY.SHOW_CONTENT, event_label: contentName })
        if (isActive) window.gtag('event', 'show_content_3d', { event_category: CATEGORY.SHOW_CONTENT, event_label: contentName });
    }


    /**
     * コンテンツを表示したときに2d
     */
    static showContent2d(contentName: string, id: number) {
        Gtag.firstShowContent()

        this.sendPageView(contentName, id);

        if (isDebug) console.log({ gtag: 'show_content_2d', event_category: CATEGORY.SHOW_CONTENT, event_label: contentName })
        if (isActive) window.gtag('event', 'show_content_2d', { event_category: CATEGORY.SHOW_CONTENT, event_label: contentName });
    }


    /**
     * コンテンツを表示したときに2d Standard
     */
    static showContentStandard(contentName: string, id: number) {
        Gtag.firstShowContent()
        
        this.sendPageView(contentName, id);

        if (isDebug) console.log({ gtag: 'show_content_standard', event_category: CATEGORY.SHOW_CONTENT, event_label: contentName })
        if (isActive) window.gtag('event', 'show_content_standard', { event_category: CATEGORY.SHOW_CONTENT, event_label: contentName });
    }


    /**
     * モード切り替えをクリック(下部ボタン)
     */
    static clickChangeModeButton(toMode: string) {
        Gtag.firstChangeMode("button")

        if (isDebug) console.log({ gtag: 'click_changemode_button', event_category: CATEGORY.CHANGE_MODE, event_label: toMode })
        if (isActive) window.gtag('event', 'click_changemode_button', { event_category: CATEGORY.CHANGE_MODE, event_label: toMode });
    }


    /**
     * モード切り替えをクリック(ヘッダーボタン)
     */
    static clickHeaderModeButton(toMode: string) {
        Gtag.firstChangeMode("header")

        if (isDebug) console.log({ gtag: 'click_heeadermode_button', event_category: CATEGORY.CHANGE_MODE, event_label: toMode })
        if (isActive) window.gtag('event', 'click_heeadermode_button', { event_category: CATEGORY.CHANGE_MODE, event_label: toMode });
    }


    /**
     * コンテンツのマターポートを表示
     */
    static openMatterport(contentName: string) {
        if (isDebug) console.log({ gtag: 'open_matterport', event_category: CATEGORY.CONTENT, event_label: contentName })
        if (isActive) window.gtag('event', 'open_matterport', { event_category: CATEGORY.CONTENT, event_label: contentName });
    }


    /**
     * コンテンツの動画を再生
     */
    static playVideo(contentName: string, seektime: number) {
        if (isDebug) console.log({ gtag: 'play_video', event_category: CATEGORY.CONTENT, event_label: contentName, value: seektime })
        if (isActive) window.gtag('event', 'play_video', { event_category: CATEGORY.CONTENT, event_label: contentName, value: seektime });
    }


    /**
     * コンテンツの動画を停止
     */
    static stopVideo(contentName: string, seektime: number) {
        if (isDebug) console.log({ gtag: 'stop_video', event_category: CATEGORY.CONTENT, event_label: contentName, value: seektime })
        if (isActive) window.gtag('event', 'stop_video', { event_category: CATEGORY.CONTENT, event_label: contentName, value: seektime });
    }


    /**
     * コンテンツのリンクをクリック
     */
    static clickLink(contentName: string) {
        if (isDebug) console.log({ gtag: 'click_link', event_category: CATEGORY.CONTENT, event_label: contentName })
        if (isActive) window.gtag('event', 'click_link', { event_category: CATEGORY.CONTENT, event_label: contentName });
    }


    /**
     * ロゴクリック
     */
    static clickLogo() {
        if (this.isEnableLogoClick) {
            this.isEnableLogoClick = false

            if (isDebug) console.log({ gtag: 'click_logo', event_category: CATEGORY.OTHER })
            if (isActive) window.gtag('event', 'click_logo', { event_category: CATEGORY.OTHER });

            window.setTimeout(() => this.isEnableLogoClick = true, clickLimmiter)
        }
    }


    /**
     * コンテンツを隠す時に呼ばれる
     */
    static hideContent() {
        if(isActive){
            window.gtag('event', 'page_view', {
                page_title: "home",
                page_path: `/`,
            })
        }
    }

    
    /**
     * ページ移動としてイベントを送信する
     */
    static sendPageView(title: string, path: number) {
        if(isActive){
            window.gtag('event', 'page_view', {
                page_title: title,
                page_path: `/${path}`,
            })
        }
    }
}

export default Gtag